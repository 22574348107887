<template>
  <div class="create-edit-obj">
    <!-- 查找/查找多选 -->
    <!-- 搜索 -->
    <el-dialog
      :title="$t('label.quickbooks.searchs')"
      custom-class="dialog-form-wrapper"
      width="60%"
      top="5%"
      :visible.sync="showSearchTable"
      :lock-scroll="true"
      :close-on-click-modal="false"
      :modal="true"
      append-to-body
    >
      <search-table
        ref="searchTable"
        :fieldId="fieldId"
        :checked="checked"
        :relevant-objid="relevantObjId"
        :relevant-prefix="relevantPrefix"
        @setFieldReltaion="setFieldReltaion"
        @changeSelect="changeSlaveSelect"
      />
    </el-dialog>
    <!-- 新增/修改记录 -->
    <div class="detailHeight">
      <div style="text-align: center" v-if="!showBaseForm">
        <i class="el-icon-loading" style="font-size: 30px"> </i>
      </div>
      <base-form
        ref="baseForm"
        class="baseForm"
        v-if="showBaseForm"
        :id="id"
        :prefix="prefix"
        :objectApi="objectApi"
        :form-attr="formAttr"
        :operation-type="dialogAttr.type"
        :columnNum="columnNum"
        :oneColumnWidth="oneColumnWidth"
        :twoColumnWidth="twoColumnWidth"
        :option-list="optionList"
        :status-icon="statusIcon"
        :autoRefresh="autoRefresh"
        :contactes="contactes"
        @addFile="addFile"
        @notZhucongfield="notZhucongfield"
        @changeSelect="changeSelect"
        @control="control"
        @lookupValue="lookupValue"
        @handleAction="handleAction"
        @remoteMethod="remoteMethod"
        @newSearchableField="newSearchableField"
        @addFieldOption="addFieldOption"
        @fwbContent="fwbContent"
        :isClientPoolEditStatus="isClientPoolEditStatus"
        :relativeRecordId="relativeRecordId"
        :isPartner="isPartner"
        :isNewBuild="true"
      >
      </base-form>
      <!-- 产品列表 -->
      <div v-if="showBaseForm" class="product-list">
        <div class="product-body">
          <div class="product" style="background: #fafaf9; font-weight: 600">
            <div
              class="item title"
              v-for="(item, index) in titleDate"
              :key="index"
            >
              {{ item }}
            </div>
          </div>
          <div
            style="
              border-left: 1px solid #dcdfe6;
              border-right: 1px solid #dcdfe6;
            "
          >
            <div
              class="product list"
              v-for="(item, index) in listDate"
              :key="index"
            >
              <div class="item">{{ item.productcode }}</div>
              <div class="item">{{ item.productname }}</div>
              <div class="item picture">
                <img
                  :src="oldImgSrcBefore + item.imageid + oldImgSrcAfter"
                  alt=""
                  v-if="item.imageid"
                />
              </div>
              <div class="item">{{ thousands(item.listprice) }}</div>
              <div class="item count">
                {{ thousands(item.quantity) }}
              </div>
              <div class="item">{{ thousands(item.discount) }}</div>
              <!-- 金额 -->
              <div class="item">{{ thousands(item.customertotal) }}</div>
              <!-- 税 -->
              <div class="item">{{ thousands(item.retaprice) }}</div>
              <!-- 总价 -->
              <div class="item">{{ thousands(item.zongjia) }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 潜在客户和个案时展示分配 -->
      <div
        v-if="
          showBaseForm &&
          (objectApi === 'Lead' ||objectApi === 'cloudcclead' ||
            objectApi === 'cloudcccase')
        "
      >
        <p class="groupTitle">
          <!-- 可选 -->
          {{ $t("label.assign") }}
        </p>
        <el-checkbox v-model="validRule" style="margin-bottom: 20px">
          <!-- 使用有效的分配规则进行分配 -->
          {{ $t("label.assignment.layout.field.isassigned") }}
        </el-checkbox>
      </div>
    </div>
    <div class="footer">
      <div style="display: flex; justify-content: end; align-items: center">
        <div style="display: none">
          <!-- 布局切换按钮 -->
          <ul class="changeColumnBtnGroup">
            <li
              @click="changeColumns('twoColumns')"
              style="border-right: 1px solid #dedcda"
            >
              <svg
                class="icon setColumnsIcon"
                aria-hidden="true"
              >
              <use :fill="detailMode === 'twoColumns'?'#006dcc':'#f5f5f5'" href="#icon-baocunbuju"></use>
              </svg>
            </li>
            <li @click="changeColumns('responsiveLayout')">
              <svg
                v-if="detailMode === 'responsiveLayout'"
                class="icon setColumnsIcon autoColumnsIcon"
                aria-hidden="true"
              >
                <use href="#icon-autoColumns-active"></use>
              </svg>
              <svg
                v-else
                class="icon setColumnsIcon autoColumnsIcon"
                aria-hidden="true"
              >
                <use href="#icon-autoColumns"></use>
              </svg>
            </li>
          </ul>
        </div>
        <div style="display: flex; align-items: center; justify-content: end">
          <div class="priceTotal">
            <div>
              <span
                >{{ $t("c98") }}：
                <p>{{ thousands(cartInfo.productQuantity) }}</p>
                {{ $t("c120") }}</span
              >|
              <span
                >{{ $t("c99") }}：
                <p>{{ thousands(cartInfo.totalCount) }}</p>
                {{ $t("c101") }}</span
              >|
              <span
                >{{ $t("lable.product.zongjia") }}：<span class="money"
                  >{{ currencyCode }} {{ thousands(cartInfo.totalPrice) }}</span
                >
              </span>
            </div>
            <div class="span">
              {{ $t("c100") }}：{{ currencyCode }}
              {{ thousands(cartInfo.Reduction) }}
            </div>
          </div>
          <el-button size="mini" @click="goShoppingCar">
            {{ $t("label.partnerCloud.orderingPlatform.adjust") }}
          </el-button>
          <!-- 保存并提交 -->
          <el-button
            size="mini"
            :loading="isSaveBtnLoadin"
            @click="save('saveandapproval')"
          >
            {{ $t("label.submitforapproval") }}
          </el-button>
          <!-- 保存并支付 -->
          <el-button
            v-show="showpaybutton"
            size="mini"
            :loading="isSaveBtnLoadin"
            @click="save('pay')"
          >
            {{ $t("c117") }}
          </el-button>
          <!-- 保存 -->
          <el-button
            size="mini"
            type="primary"
            v-preventReClick
            :loading="isSaveBtnLoadin"
            @click="save('detail')"
          >
            <!-- 保存 -->
            {{ $t("label.save") }}
          </el-button>
        </div>
      </div>
    </div>

    <!-- 字段重复提示 -->
    <el-dialog
      :title="duplicateFieldDialogAttr.title"
      :visible.sync="duplicateFieldDialogAttr.isShow"
      top="15%"
      :close-on-click-modal="false"
      :modal="true"
      :width="duplicateFieldDialogAttr.dialogWidth"
      :modal-append-to-body="false"
      class="dialog"
      append-to-body
    >
      <p>
        与<a :href="duplicateHref">{{ duplicate_name }}</a
        >中{{ duplicate_labelname }}重复
      </p>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button size="mini" @click="duplicateFieldDialogAttr.isShow = false">
          <!-- 取消 -->
          {{ $t("button_partner_cancel") }}
        </el-button>
      </div>
    </el-dialog>

    <!-- 添加文件 -->
    <add-files
      ref="addFile"
      :isFromFileCenter="false"
      :dialogAddVisible="dialogAddVisible"
      @addSubmit="addWorkSubmit"
      @close="addWorkClose"
    >
    </add-files>
    <!-- 提交待审批弹窗 -->
    <nextApproval
      ref="nextApprovalref"
      @approvalSuccess="approvalSuccess"
    ></nextApproval>
    <div>
      <!--  '无法提交待审批'  -->
      <el-dialog
        :title="$t('message.approval.cannotsubmit')"
        :visible.sync="failedDialog"
        width="30%"
      >
        <span>{{ errInfo }}</span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { BaseForm, AddFiles } from "@/components/index";
import {
  queryPartnerFund,
  getPayMethod,
  getViewListRecently,
  submitApproval,
  getPartnerSavePartnerUser,
  deletechooseproduct,
} from "./api.js";
import * as CommonObjApi from "./api";
import INPUTTYPE from "@/config/enumCode/inputType";
import Bus from "@/mixin/bus.js";
import VueCookies from "vue-cookies";
import detailSendCard from "@/utils/robotMessage.js";
import nextApproval from "@/views/approval/components/approval-dialog";

export default {
  components: {
    BaseForm,
    AddFiles,
    nextApproval,
  },
  props: {
    // 记录类型
    recordType: {
      type: String,
      default: "",
    },
    // 价格手册name
    priceBookName: {
      type: String,
      default: "",
    },
    // 订单总价
    totalPrice: {
      type: Number,
      default: 0,
    },
    // 主页传过来的数据名称
    homeNweName: {
      type: String,
      default: "",
    },
    // 主页传过来的id
    homeNweId: {
      type: String,
      default: "",
    },
    // 主页传过来的潜在客户和联系人名称
    homeContactName: {
      type: String,
      default: "",
    },
    // 主页传过来的数据相关项类型
    homeNweObjid: {
      type: String,
      default: "",
    },
    // 主页新建名称相关项是否可以编辑
    standardHomePage: {
      type: String,
      default: "",
    },
    // 对象前缀
    prefix: {
      type: String,
      default: "",
    },
    // 对象Api（路由传递）
    objectApi: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    objectName: {
      type: String,
      default: "",
    },
    bigTitle: {
      type: Array,
    },
    objectApies: {
      type: String,
      default: "",
    },
    relevantListFlag: {
      type: Boolean,
      default: true,
    },
    relativeRecordId: {
      type: String,
      default: "",
    },
    relationFieldId: {
      type: String,
      default: "",
    },
    recordIds: {
      type: String,
      default: "",
    },
    dataListPartner: {
      type: Array,
    },
    // 公海池页面标记
    pageFlag: {
      type: String,
      default: "",
    },
    objectNames: {
      type: String,
    },
    isActive: {
      type: String,
    },
    // objid
    realObjId: {
      type: String,
      default: "",
    },
    // 当前选中的公海池对象
    currentClientPoolSelectObj: {
      type: Object,
      default: () => ({}),
    },
    // 公海池的编辑状态【在create-eit-obj组件】
    isClientPoolEditStatus: {
      type: Boolean,
      default: false,
    },
    // type为active时为快速新建
    type: {
      type: String,
      default: "",
    },
    //产品表头数据
    titleDate: {
      type: Array, //'产品代码', '产品名称', '图片', '单价', '购买数量', '优惠', '总价'
      default() {
        return [
          this.$i18n.t("label.product.code"),
          this.$i18n.t("label.product.name"),
          this.$i18n.t("label.file.img"),
          this.$i18n.t("label.partnerCloud.orderingPlatform.unitPrice"),
          this.$i18n.t("label.partnerCloud.orderingPlatform.quantity"),
          this.$i18n.t("label.partnerCloud.orderingPlatform.preferential"),
          this.$i18n.t("label.stdashbord.xsld.business.jine"),
          this.$i18n.t("c5"),
          this.$i18n.t("lable.product.zongjia"),
        ];
      },
    },
    listDate: {
      type: Array,
      default: () => {
        return [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }];
      },
    },
  },
  data() {
    return {
      cartInfo: this.$route.query.cartInfo,
      showpaybutton: true,
      nullElement: null,
      currencyCode: this.$store.state.currencyCode,
      isPartner: true,
      oldImgSrcBefore: `${this.$cookies.get(
        "domainName"
      )}/querysome.action?m=viewImg&fileid=`,
      oldImgSrcAfter: `&binding=${this.$CCDK.CCToken.getToken()}`,
      //存储商品信息
      productInfo: [],
      // id: '',
      detailMode: "",
      columnNum: 2,
      oneColumnWidth: "100%",
      twoColumnWidth: "50%",
      failedDialog: false, // 无法提交待审批弹窗状态
      errInfo: "", // 无法提交待审批错误提示
      possibilityList: [],
      duplicateHref: "",
      duplicate_name: "",
      duplicate_labelname: "",
      saveandapproval: false, // 展示保存并新建按钮
      isZhucongfield: false,
      flag: "",
      rowIndex: "",
      apiname: "",
      ind: 0,
      alldataists: [],
      recordId: "", //主从记录ID
      editableCellValue: { value: "" },
      ownerName: "",
      ownerId: "",
      clientOwnerName: "",
      dataLists: [],
      showSearchTable: false,
      fieldId: "",
      relevantObjId: "",
      relevantPrefix: "",
      checkList: [],
      duplicateFieldDialogAttr: {
        title: "提示", //提示
        isShow: false,
        dialogWidth: "25%",
      },
      recordTypeDialogAttr: {
        title: this.$i18n.t("label_tabpage_selrecordtypez"), //选择记录类型
        isShow: false,
        dialogWidth: "25%",
      },
      dialogAttr: {
        title: "",
        type: "add",
        isShow: false,
        dialogWidth: "85%",
      },
      subordinateFields: [], // 从对象字段信息
      fieldlist: [], //表头信息
      isaddShow: true,
      transferClientOwnerDialog: false,
      stateone: "",
      validRule: true,
      state: "",
      selectNum: 0,
      checked: false,
      isSendMail: false,
      transferOwnerDialog: false,
      optionList: {},
      pictLoading: false,
      printFlag: false,
      lengths: 0,
      showKanban: false,
      statusIcon: true,
      autoRefresh: false,
      showBaseForm: false,
      recordOptions: [],
      formAttr: null,
      showScreen: false, // 是否显示筛选器
      isLabelView: false, //显示标签
      isChart: false, //显示图表
      viewIsmodify: false, // 当前视图是否可编辑
      url: "",
      tabName: "",
      relatedObjectApis: [], //主从relatedObjectApis
      relatedApinames: [], //主从relatedApiname
      objId: this.$route.params.objId, // 对象id
      tableSearchParams: {},
      inputWidth: "300px",
      timer: "", //定义一个定时器的变量
      lastRefresh: new Date(), // 最后一次更新时间
      timeInterval: "",
      newViewId: "",
      labelArr: [],
      str: "",
      isQueryCount: false,
      viewInfo: {},
      strs: [],
      lookupValues: [], // 查找字段
      controlContainer: null, //依赖关系
      relatedLookupValues: [],
      relatedControlContainer: [], //依赖关系
      actionRequireds: [], // 动态布局必填字段
      actionReadonlys: [], // 动态布局只读字段
      actionShowFields: [], // 动态布局显示字段
      actionShowSections: [], // 动态布局显示部分
      ismodify: "",
      relatedItem: {}, // 事件类型的相关项
      currentUserInfo: {}, // 当前登录用户
      eventDate: {}, // 事件类型的日期
      calendarData: {}, // 日历新建填入的数据
      shiftData: {}, // 轮班新建填入的数据
      calendarUser: {}, // 日历新建传入的人员信息
      dialogBodyHeight: `${document.body.offsetHeight - 248}px`,
      addable: "",
      dialogAddVisible: false,
      newObjLevel: 0, // 新建查找字段标识
      fieldProp: "",
      userUrl: "",
      token: this.$CCDK.CCToken.getToken(),
      validRelatedObj: false, //从对象信息必填校验
      isRepeatTime: false, //是否有时间段重复
      relatedObjPrefix: "",
      contactes: "",
      actives: "",
      fwb: "",
      attrIndex: -1, // 表单字段所在位置
      isSaveBtnLoadin: false, // 保存按钮loading
      // 新建表单创建的订单id
      orderId: "",
      accountInfo: {},
      pricebook2id: "",
      addrArr: [], //存放地址字段子级数组
      newCreate: "", //判断当前新建是否为新建订单或发票页面再进行客户地址新建
    };
  },
  beforeRouteUpdate(to, from, next) {
    next();
  },
  created() {
    this.userUrl = this.$cookies.get("domainName");
  },
  mounted() {
    document.title = this.$setTitle(this.$i18n.t("label.ordering.platform"));
    Bus.$on("windowResize", this.ebFn);
    this.getStatement();
    this.showPayButton();
  },
  methods: {
    ebFn(offsetHeight){
      this.dialogBodyHeight = `${offsetHeight - 248}px`;
      this.setDetailMode();
    },
    //删除当前选中的产品
    async deletechooseproduct() {
      await deletechooseproduct({
        productids: this.listDate.map((res) => res.product).join(),
      });
    },
    // 设置千分位样式  dj
    thousands(num) {
      if (num) {
        var parts = num.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
      }
    },
    // 获取是否展示保存并支付按钮
    async showPayButton() {
      let result = await getPayMethod({});
      if (
        result.data.isOnlinePayment === "true" ||
        result.data.avaliable !== "" ||
        result.data.isUsingPartnerFund == "true"
      ) {
        this.showpaybutton = true;
      } else {
        this.showpaybutton = false;
      }
    },
    // 返回购物车
    goShoppingCar() {
      this.$router.push({
        path: "/orderingShoppingCar",
      });
    },
    // 获取当前客户id  name
    async getStatement() {
      let obj = {
        startDate: "",
        endDate: "",
        status: "",
        operType: "",
        pageNUM: "",
        pageSize: "",
      };
      let result = await queryPartnerFund(obj);
      this.accountInfo = result.data.accountInfoList[0];
    },
    //   准备商品数据
    saveProductInfo() {
      let array = [];
      this.listDate.map((item) => {
        let obj = {
          productcode: item.productcode, //产品代码
          product2id: item.product, //产品id
          product2idccname: item.productname,
          unitprice: item.listprice, //单价
          quantity: item.quantity, //数量
          pricebook2id: item.pricebook,
          quotedquantity: item.quantity, //产品数量
          amount: item.customertotal, //金额
          discountamount: item.discount, //折扣
          tax: item.retaprice, //税
          totalprice: item.zongjia, //总价
        };
        array.push(obj);
        this.pricebook2id = item.pricebook;
      });
      this.productInfo = array;
    },
    // 设置两列布局/响应式布局
    setDetailMode() {
      this.$nextTick(() => {
        if (this.detailMode !== "twoColumns") {
          this.calcDetailInfoWidth();
        } else {
          this.changeColumns("twoColumns");
        }
      });
    },
    //提交待审批成功后跳转详情页(弹窗)
    approvalSuccess() {
      this.$router.push({
        path: `/commonObjects/detail/${this.recordId}/DETAIL`,
      });
    },
    addFieldOption(prop, option) {
      if (this.optionList[prop]) {
        this.optionList[prop].push(option);
      } else {
        this.optionList[prop] = [option];
      }
    },
    // 轮班视图点击格子新建
    handleShift(obj) {
      this.shiftData = obj;
      this.add();
    },
    // 日历视图传入的相关参数
    toDoList(obj) {
      // this.prefix = obj.prefix
      // this.objectApi = obj.api
      this.objId = obj.type;
      this.eventDate = obj.timeObj || {};
      this.calendarData = obj.config || {};
      this.calendarUser = obj.userInfo || {};
      this.add();
    },
    // 计算详细信息宽度
    calcDetailInfoWidth() {
      if (document.getElementsByClassName("detailHeight")[0]) {
        let width =
          document.getElementsByClassName("detailHeight")[0].clientWidth;
        if (width <= 1280 - 72) {
          this.setColumnsMode("twoColumns");
        } else if (1280 - 72 < width && width <= 1920 - 72) {
          this.setColumnsMode("fourColumns");
        } else if (width > 1920 - 72) {
          this.setColumnsMode("sixColumns");
        }
      }
    },
    // 二列布局和响应式布局切换
    changeColumns(columns) {
      if (columns === "twoColumns") {
        VueCookies.set(`${this.objectApi}DetailMode`, "twoColumns", { sameSite: 'Strict' });
        this.setColumnsMode("twoColumns");
      } else {
        VueCookies.set(`${this.objectApi}DetailMode`, "responsiveLayout", { sameSite: 'Strict' });
        this.calcDetailInfoWidth();
      }
      Bus.$emit("setColumnsMode", columns);
      this.detailMode = VueCookies.get(`${this.objectApi}DetailMode`);
    },
    // 新建
    add(val, Wval, type, newCreate) {
      //判断当前新建是否为新建订单或发票页面再进行客户地址新建
      this.newCreate = newCreate;
      this.actives = val;
      // eslint-disable-next-line vue/no-mutating-props
      this.recordType = "";
      this.validRule = false;
      this.formAttr = [];
      this.optionList = {};
      this.showBaseForm = false;
      this.getCurrentUser();
      // this.getRecordType()
      // 新建
      this.dialogAttr.title = `${this.$i18n.t("label.ems.create")} ${
        this.objectName
      }`;
      this.dialogAttr.type = "NEW";
    },
    // 修改记录类型后编辑
    editAfterChangeRecordType(recordType) {
      // eslint-disable-next-line vue/no-mutating-props
      this.recordType = recordType;
      this.validRule = false;
      this.showBaseForm = false;
      this.dialogAttr.type = "EDIT";
      this.recordOptions = [];
      this.getFormAttr();
      // 编辑
      //项目列表编辑子任务
      // if (
      //   this.$route.path.substring(this.$route.path.length - 3) == "p03" &&
      //   row.data.their_task
      // ) {
      //   this.dialogAttr.title = `${this.$i18n.t(
      //     "pagecreator_page_button_edit"
      //   )} 子任务`;
      // } else {
      //   this.dialogAttr.title = `${this.$i18n.t(
      //     "pagecreator_page_button_edit"
      //   )} ${this.objectName}`;
      // }
      this.dialogAttr.isShow = true;

      this.setDetailMode();
    },
    // 编辑
    edit(row, type, types, newCreate) {
      //判断当前新建是否为新建订单或发票页面再进行客户地址新建
      this.newCreate = newCreate;
      // eslint-disable-next-line vue/no-mutating-props
      this.recordType = "";
      this.validRule = false;
      this.showBaseForm = false;
      this.dialogAttr.type = "EDIT";
      this.recordOptions = [];
      //知识文章更改记录类型
      if (type) {
        // eslint-disable-next-line vue/no-mutating-props
        this.recordType = type;
        CommonObjApi.getRecordType({ prefix: this.prefix }).then((res) => {
          if (
            res.data.recordTypeList !== null &&
            res.data.recordTypeList !== undefined
          ) {
            res.data.recordTypeList.forEach((record) => {
              this.recordOptions.push({
                val: record.id,
                key: record.name,
              });
            });
            if (res.data.recordTypeList.length > 1) {
              this.recordTypeDialogAttr.isShow = true;
            }
          }
        });
      } else {
        this.dialogAttr.isShow = true;
      }
      this.getFormAttr();
      // 编辑
      //项目列表编辑子任务
      if (
        this.$route.path.substring(this.$route.path.length - 3) == "p03" &&
        row.data.their_task
      ) {
        this.dialogAttr.title = `${this.$i18n.t(
          "pagecreator_page_button_edit"
        )} 子任务`;
      } else {
        this.dialogAttr.title = `${this.$i18n.t(
          "pagecreator_page_button_edit"
        )} ${this.objectName}`;
      }
      // this.dialogAttr.isShow = true;
      this.setDetailMode();
    },
    // 复制
    copy() {
      this.getCurrentUser();
      this.validRule = false;
      this.showBaseForm = false;
      this.dialogAttr.type = "COPY";
      CommonObjApi.getRecordType({ prefix: this.prefix }).then((res) => {
        if (
          res.data.recordTypeList !== null &&
          res.data.recordTypeList !== undefined
        ) {
          res.data.recordTypeList.forEach((record) => {
            this.recordOptions.push({
              val: record.id,
              key: record.name,
            });
          });
        }
      });
      this.getFormAttr();
      // 复制
      this.dialogAttr.title = `${this.$i18n.t("label.clone")} ${
        this.objectName
      }`;
      this.dialogAttr.isShow = true;
      this.setDetailMode();
    },
    //联系人第一次启用外部用户
    contacts() {
      // eslint-disable-next-line vue/no-mutating-props
      this.recordType = "";
      this.validRule = false;
      this.showBaseForm = false;
      this.contactes = "contactsNew";
      this.getFormAttr("contactsNew");
      // 新建
      this.dialogAttr.title = this.$i18n.t("label.ems.create");
      this.dialogAttr.isShow = true;
      this.setDetailMode();
    },
    // 下一步 打开新建/编辑弹窗
    next() {
      this.formAttr = [];
      this.getFormAttr();
      if (this.dialogAttr.type === "NEW" || this.dialogAttr.type === "COPY") {
        // this.getZhuCong()
      }
      this.cancelRecordTypeDialog();
      this.dialogAttr.isShow = true;
      this.setDetailMode();
    },
    // 获取当前登录用户
    getCurrentUser() {
      // 获取登录用户
      this.currentUserInfo = {
        id: localStorage.getItem("userId"),
        name: localStorage.getItem("username"),
      };
    },
    // 事件类型的相关项处理
    getRelatedItem() {
      // 获取相关项
      CommonObjApi.getDialigItem({ type: this.objId }).then((res) => {
        if (res.result && res.returnCode === "1") {
          this.relatedItem = {
            reItem: res.data.relateobjList,
            naItem: res.data.whoobjInfoList,
          };
          this.formAttr.map((item) => {
            if (item.prop === "relateid") {
              item.type = "eventTypeB";
              item.optionItem = this.relatedItem.reItem;
              item.width = "100%";
              if (this.isActive === "active") {
                if (
                  this.objectApies !== "Lead" &&
                  this.objectApies !== "cloudcclead" &&
                  this.objectApies !== "Contact"
                ) {
                  item.disabled = true;
                } else {
                  item.disabled = false;
                }
              } else if (this.standardHomePage === "homepage") {
                if (
                  this.homeNweObjid !== "Lead" &&
                  this.homeNweObjid !== "cloudcclead" &&
                  this.homeNweObjid !== "Contact"
                ) {
                  item.disabled = true;
                } else {
                  item.disabled = false;
                }
              } else if (this.calendarData.id) {
                if (
                  this.calendarData.prefix !== "003" &&
                  this.calendarData.prefix !== "004"
                ) {
                  item.disabled = true;
                } else {
                  item.disabled = false;
                }
              } else {
                item.disabled = false;
              }
            }
            if (item.prop === "whoid") {
              item.type = "eventTypeA";
              item.optionItem = this.relatedItem.naItem;
              item.width = "100%";
              if (this.isActive === "active") {
                if (
                  this.objectApies === "Lead" ||
                  this.objectApies === "cloudcclead" ||
                  this.objectApies === "Contact"
                ) {
                  item.disabled = true;
                } else {
                  item.disabled = false;
                }
              } else if (this.standardHomePage === "homepage") {
                if (
                  this.homeNweObjid === "Lead" ||
                  this.homeNweObjid === "cloudcclead" ||
                  this.homeNweObjid === "Contact"
                ) {
                  item.disabled = true;
                } else {
                  item.disabled = false;
                }
              } else if (this.calendarData.id) {
                if (
                  this.calendarData.prefix === "003" ||
                  this.calendarData.prefix === "004"
                ) {
                  item.disabled = true;
                } else {
                  item.disabled = false;
                }
              } else {
                item.disabled = false;
              }
            }
          });
        } else {
          this.$message.error(res.returnInfo);
        }
      });
      //
    },
    // 获取当前系统时间的下一个整点以及整点之后一个小时的时间
    getHour() {
      let date = new Date();
      date.setHours(date.getHours() + 1);
      let y = date.getFullYear();
      let m =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      let d = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      let h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      let startTime = y + "-" + m + "-" + d + " " + h + ":00:00";
      function getNextHour(intPoint) {
        let dd = new Date(intPoint);
        let Y = dd.getFullYear();
        let M =
          dd.getMonth() + 1 < 10
            ? "0" + (dd.getMonth() + 1)
            : dd.getMonth() + 1;
        let D = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
        let H =
          dd.getHours() + 1 < 10
            ? "0" + (dd.getHours() + 1)
            : dd.getHours() + 1;
        return Y + "-" + M + "-" + D + " " + H + ":00:00";
      }
      return { start: startTime, end: getNextHour(startTime) };
    },
    // 远程搜索
    remoteMethod(params, item, formData) {
      //在项目管理系统下对负责人所属项目的判断
      if (
        item.apiname == "principal" ||
        item.apiname == "member" ||
        (window.location.href.substring(window.location.href.length - 3) ==
          "p04" &&
          (item.apiname == "milestone" ||
            item.apiname == "affected_milestones"))
      ) {
        if (
          formData.project_name == "" ||
          formData.their_project == "" ||
          formData.their_project_name == ""
        ) {
          this.$message.error(
            this.$i18n.t("label.projectManagement.selectitem")
          ); //"请选择项目"
          return;
        }
      }
      if (
        (window.location.href.substring(window.location.href.length - 3) !==
          "p03" ||
          window.location.href.substring(window.location.href.length - 3) !==
            "p05") &&
        item.apiname !== "their_milestone"
      ) {
        params.projectId =
          formData.their_project ||
          formData.project_name ||
          formData.their_project_name;
      }
      // 查询是否有默认条件
      CommonObjApi.getLookupInfo({
        fieldId: params.fieldId,
        objId: item.objId,
        prefix: item.prefix,
      }).then((res) => {
        // eslint-disable-next-line no-unused-vars
        // let lkdp = [""];
        let values = [''];

        // 开启查找筛选
        if (res.data.isEnableFilter === "true") {
          for (let reltaion in res.data.filterFieldIds) {
            let attr = this.formAttr.find((attrItem) => {
              return (
                attrItem.fieldId === res.data.filterFieldIds[reltaion].fieldid
              );
            });
            if (
              attr !== undefined &&
              formData[attr.prop] !== null &&
              Array.isArray(formData[attr.prop])
            ) {
              values.push(formData[attr.prop].join(";"));
            } else if (attr !== undefined && formData[attr.prop] !== null) {
              values.push(formData[attr.prop]);
            }
          }
        }
        params.lkdp = JSON.stringify(values);
        CommonObjApi.queryMoreDataByName(params).then((res) => {
          let options = JSON.parse(res.data);
          options.forEach((option) => {
            option.value = option.id;
            option.label = option.queryname;
          });
          this.$set(this.optionList, item.prop, options);
        });
      });
    },
    // 新增查找、查找多选字段
    newSearchableField(item) {
      // 获取对象api后进行页面跳转
      CommonObjApi.getLookupInfo({
        fieldId: item.fieldId,
        objId: item.objId,
        prefix: item.prefix,
      }).then((res) => {
        let objectApi = res.data.objectApi;
        window.open(
          `#/add-relevant-obj/${item.prefix}/${objectApi}/${this.newObjLevel}?addType=option&prop=${item.prop}`
        );
      });
    },
    // 添加文件
    addFile(field) {
      this.fieldProp = field.prop;
      this.$refs.addFile.initPage();
      this.dialogAddVisible = true;
    },
    // 添加文件
    addWorkSubmit(checkedFileList) {
      let ids = [];
      checkedFileList.forEach((file) => {
        if (file.fileInfoId) {
          ids.push(file.fileInfoId);
        } else {
          ids.push(file.id);
        }
      });
      this.$set(this.$refs.baseForm.formData, this.fieldProp, ids.toString());
      this.formAttr.map((attr) => {
        if (attr.prop === this.fieldProp) {
          attr.data = checkedFileList;
        }
      });
      this.addWorkClose();
    },
    // 关闭添加文件
    addWorkClose() {
      this.dialogAddVisible = false;
    },
    // 获取记录类型
    getRecordType() {
      this.recordOptions = [];
      CommonObjApi.getRecordType({ prefix: this.prefix }).then((res) => {
        if (
          res.data.recordTypeList !== null &&
          res.data.recordTypeList !== undefined
        ) {
          res.data.recordTypeList.forEach((record) => {
            // 设置默认记录类型
            if (record.isdefault === "true") {
              // eslint-disable-next-line vue/no-mutating-props
              this.recordType = record.id;
            }

            return this.recordOptions.push({
              val: record.id,
              key: record.name,
            });
          });
          // 若没有默认记录类型，设置第一个为默认
          if (this.recordType === "") {
            // eslint-disable-next-line vue/no-mutating-props
            this.recordType =
              this.recordOptions.length > 0 && this.dialogAttr.type !== "EDIT"
                ? this.recordOptions[0].val
                : "";
          }
          // recordType未赋上值或者只有一个记录类型时，展示记录详情
          if (this.recordType === "" || this.recordOptions.length === 1) {
            this.dialogAttr.isShow = true;
            this.setDetailMode();
            this.getFormAttr();
            // this.getZhuCong()
          }

          // 若默认记录类型只有一个，跳过 选择记录类型
          if (res.data.recordTypeList.length > 1) {
            this.recordTypeDialogAttr.isShow = true;
          } else {
            this.dialogAttr.isShow = true;
            this.setDetailMode();
          }
        } else {
          this.dialogAttr.isShow = true;
          this.setDetailMode();
          this.getFormAttr();
          // this.getZhuCong()
        }
      });
    },
    // 246列布局设置
    setColumnsMode(detailMode) {
      switch (detailMode) {
        case "twoColumns":
          this.columnNum = 2;
          this.oneColumnWidth = "100%";
          this.twoColumnWidth = "50%";
          break;
        case "fourColumns":
          this.columnNum = 4;
          this.oneColumnWidth = "50%";
          this.twoColumnWidth = "25%";
          break;
        case "sixColumns":
          this.columnNum = 6;
          this.oneColumnWidth = `${100 / 3}%`;
          this.twoColumnWidth = `${100 / 6}%`;
          break;
      }
    },
    getFormAttr(type) {
      this.attrIndex = -1;
      this.formAttr = [];
      this.optionList = {};
      this.showBaseForm = false;

      // 246列布局设置
      if (VueCookies.get(`${this.objectApi}DetailMode`) === null) {
        VueCookies.set(`${this.objectApi}DetailMode`, "twoColumns", { sameSite: 'Strict' });
      }
      this.detailMode = VueCookies.get(`${this.objectApi}DetailMode`);

      let params = {
        id: this.id,
        objectApi: this.objectApi,
        recordId: this.relativeRecordId,
        fieldId: this.relationFieldId,
        operation: this.dialogAttr.type,
      };
      if (type === "contactsNew") {
        let obj = {
          controlContainer: [],
          dataList: this.dataListPartner,
          dependContainer: [],
          lookupValueExpression: "",
        };
        obj.dataList.forEach((group) => {
          if (group.showWhenEdit) {
            this.attrIndex = -1;
          }

          let colNum = group.colNum;
          if (group.showWhenEdit) {
            this.formAttr.push({
              width: "100%",
              colNum: 1,
              type: "groupTitle",
              title: group.title,
              selectionId: group.selectionId,
              groupShow: true,
              hidden: false,
            });
          }

          // 非内嵌页面
          if (group.selectionType === "Field") {
            group.data.length > 0 &&
              group.data.forEach((line) => {
                if (!(Array.isArray(line.left) && line.left.length === 0)) {
                  let attr = this.formAttr.find((item) => {
                    return item.fieldId === line.left.fieldId;
                  });
                  if (attr === undefined) {
                    this.addFormAttr(
                      group,
                      colNum,
                      line.left,
                      undefined,
                      "left"
                    );
                  }
                } else {
                  this.attrIndex += group.colNum === 1 ? 0 : 1;
                  this.formAttr.push({
                    width: "50%",
                    colNum: 2,
                    attrIndex: this.attrIndex,
                    type: "noData",
                    hidden: false,
                    groupShow: true,
                  });
                }
                if (Number(colNum) !== 1) {
                  if (!(Array.isArray(line.right) && line.right.length === 0)) {
                    let attr = this.formAttr.find((item) => {
                      return item.fieldId === line.right.fieldId;
                    });
                    if (attr === undefined) {
                      this.addFormAttr(
                        group,
                        colNum,
                        line.right,
                        undefined,
                        "right"
                      );
                    }
                  } else {
                    this.attrIndex += group.colNum === 1 ? 0 : 1;
                    this.formAttr.push({
                      width: "50%",
                      colNum: 2,
                      attrIndex: this.attrIndex,
                      type: "noData",
                      hidden: false,
                      groupShow: true,
                    });
                  }
                }
              });
          } else {
            // 内嵌页面
            this.formAttr.push({
              width: "100%",
              colNum: 1,
              type: "customPage",
              pageUrl: group.pageUrl,
              selectionId: group.selectionId,
              groupShow: group.showWhenEdit,
              hidden: false,
            });
          }

          this.showBaseForm = true;
        });

        // 强制刷新表单
        this.$refs.baseForm && this.$refs.baseForm.filterFormData();

        // 查找带值，绑定事件
        if (
          obj.lookupValueExpression !== undefined &&
          obj.lookupValueExpression !== "" &&
          obj.lookupValueExpression !== null
        ) {
          this.setLookupValue(JSON.parse(obj.lookupValueExpression));
        }
        // 依赖关系，绑定事件
        // this.controlContainer = JSON.parse(obj.controlContainer);
        // this.setControlContainer();
        // 动态布局规则，绑定事件
        this.getDynamicLayoutListDetail(obj.layoutId);
        this.isaddShow = true;
        return;
      }
      if (this.recordType !== "") {
        params.recordtype = this.recordType;
      }
      CommonObjApi.getDetail(params).then((res) => {
        if (res.result && res.returnCode === "1") {
          // 获取从对象信息
          this.getZhuCong(res.data.layoutId);

          // 是否展示 保存并提交按钮
          this.saveandapproval =
            res.data.saveandapproval && res.data.saveandapproval === "true"
              ? true
              : false;

          res.data.dataList.forEach((group) => {
            let colNum = group.colNum;
            if (group.showWhenEdit) {
              this.formAttr.push({
                width: "100%",
                type: "groupTitle",
                title: group.title,
                selectionId: group.selectionId,
                groupShow: true,
                hidden: false,
              });
            }
            group.data.length > 0 &&
              group.data.forEach((line) => {
                if (!(Array.isArray(line.left) && line.left.length === 0)) {
                  let attr = this.formAttr.find((item) => {
                    return item.fieldId === line.left.fieldId;
                  });
                  if (attr === undefined) {
                    this.addFormAttr(
                      group,
                      colNum,
                      line.left,
                      undefined,
                      "left"
                    );
                  }
                } else {
                  this.attrIndex += group.colNum === 1 ? 0 : 1;
                  this.formAttr.push({
                    width: "50%",
                    colNum: 2,
                    attrIndex: this.attrIndex,
                    type: "noData",
                    hidden: false,
                    groupShow: true,
                  });
                }
                if (colNum !== 1) {
                  if (!(Array.isArray(line.right) && line.right.length === 0)) {
                    let attr = this.formAttr.find((item) => {
                      return item.fieldId === line.right.fieldId;
                    });
                    if (attr === undefined) {
                      this.addFormAttr(
                        group,
                        colNum,
                        line.right,
                        undefined,
                        "right"
                      );
                    }
                  } else {
                    this.attrIndex += group.colNum === 1 ? 0 : 1;
                    this.formAttr.push({
                      width: "50%",
                      colNum: 2,
                      attrIndex: this.attrIndex,
                      type: "noData",
                      hidden: false,
                      groupShow: true,
                    });
                  }
                }
              });
          });
          this.showBaseForm = true;
          this.$nextTick(() => {
            // 强制刷新表单
            this.$refs.baseForm && this.$refs.baseForm.filterFormData();

            // 查找带值，绑定事件
            if (
              res.data.lookupValueExpression !== undefined &&
              res.data.lookupValueExpression !== "" &&
              res.data.lookupValueExpression !== null
            ) {
              this.setLookupValue(JSON.parse(res.data.lookupValueExpression));
            }

            // 处理依赖关系，绑定事件
            this.controlContainer = JSON.parse(res.data.controlContainer);
            this.setControlContainer();

            // 动态布局规则，绑定事件
            this.getDynamicLayoutListDetail(res.data.layoutId);
            this.isaddShow = true;
          });
        } else {
          this.$message.error(res.returnInfo);
        }
        // 非新增操作时回填记录类型
        if (this.dialogAttr.type !== "NEW") {
          // eslint-disable-next-line vue/no-mutating-props
          this.recordType = res.data.recordType;
        }
      });
    },
    // 查找带值字段
    setLookupValue(lookupValueExpression) {
      this.lookupValues = [];
      lookupValueExpression.forEach((item) => {
        this.formAttr.forEach((attr) => {
          if (attr.apiname === item.expression.split(",")[0].toLowerCase()) {
            attr.changeEvent =
              attr.changeEvent === undefined
                ? "lookupValue"
                : `${attr.changeEvent},lookupValue`;
            // 初始化规则时走规则
            this.isZhucongfield = false;
            this.getLookupValue(item, attr.value);
          }
        });
        this.lookupValues.push(item);
      });
    },
    // 查找带值
    lookupValue(selectVal, selectItem, formData) {
      this.isZhucongfield = false;
      this.lookupValues.forEach((lookupValue) => {
        if (
          lookupValue.expression.split(",")[0].toLowerCase() ===
          selectItem.apiname
        ) {
          this.getLookupValue(lookupValue, selectVal, formData);
        }
      });
    },
    // 改变从对象查找/查找带值选项及值
    // eslint-disable-next-line no-unused-vars
    changeSlaveSelect(row, fieldId, formData) {
      let options = [];
      let optionValue = [];
      if (row instanceof Array) {
        row.forEach((ele) => {
          options.push({
            label: ele.name,
            value: ele.id,
          });
          optionValue.push(ele.id);
        });
      } else {
        options.push({
          label: row.data.name,
          value: row.data.id,
        });
        optionValue = row.data.id.toString();
      }

      let value = { value: optionValue };
      this.showSearchTable = false;
      // 主从录入
      this.$set(
        this.dataLists[this.ind][this.rowIndex - 1],
        this.apiname,
        value
      );
      this.$set(
        this.dataLists[this.ind][this.rowIndex - 1],
        `${this.apiname}Options`,
        options
      );
      this.relatedLookupValues.forEach((lookupValue) => {
        if (
          this.apiname === lookupValue.expression.split(",")[0].toLowerCase()
        ) {
          this.getLookupValue(lookupValue, optionValue);
        }
      });
    },
    // 改变查找/查找带值选项及值
    changeSelect(row, fieldId, formData) {
      let options = [];
      let fieldVal = [];
      let optionValue = [];
      if (row instanceof Array) {
        row.forEach((ele) => {
          options.push({
            label: ele.name,
            value: ele.id,
          });
          optionValue.push(ele.id);
        });
      } else {
        options.push({
          label: row.data.name,
          value: row.data.id,
        });
        optionValue = row.data.id.toString();
      }

      // eslint-disable-next-line no-unused-vars
      let value = { value: optionValue };
      this.showSearchTable = false;
      if (this.transferOwnerDialog || this.transferClientOwnerDialog) {
        this.changeSelectOwner(row);
      } else {
        this.formAttr.forEach((attr) => {
          if (attr.fieldId === fieldId) {
            this.$set(this.optionList, attr.prop, options);
            if (attr.fieldType === "MR") {
              options.forEach((option) => {
                fieldVal.push(option.value);
              });
              formData[attr.apiname] = fieldVal;
            } else {
              formData[attr.apiname] =
                row.data === undefined ? "" : row.data.id;
            }
            this.$refs.baseForm.filterOptions = [];

            // 若有查找带值，将值带入
            this.lookupValues.forEach((lookupValue) => {
              if (
                attr.apiname ===
                lookupValue.expression.split(",")[0].toLowerCase()
              ) {
                this.getLookupValue(lookupValue, optionValue, formData);
              }
            });
          }
        });
      }
    },
    // 依赖关系
    setControlContainer() {
      // 先将被控字段选项清空,循环后有对应选项再进行赋值
      this.controlContainer.forEach((control) => {
        control.controlVals.forEach((vals) => {
          vals.dependFields.forEach((dependField) => {
            this.formAttr.forEach((controlAttr) => {
              if (controlAttr.fieldId === dependField.dependfieldid) {
                this.optionList[controlAttr.prop] = [];
              }
            });
          });
        });
      });

      this.controlContainer.forEach((control) => {
        // 控制字段
        this.formAttr.forEach((attr) => {
          if (attr.fieldId === control.controlfield) {
            this.control(
              attr.value,
              attr.fieldId,
              this.$refs.baseForm.formData,
              true
            );
          }
        });
      });
    },
    // 依赖字段 ,initControl为true时是新建初始化，已经将选项值都清空，无需再次清理
    control(selectVal, fieldId, formData, initControl = false) {
      // 被控制字段集合
      let controledFieldId = [];
      let controledFieldProp = [];

      // 查找对应规则
      let control = this.controlContainer.find((control) => {
        return control.controlfield === fieldId;
      });

      if (control !== undefined) {
        // 先将被控字段选项清空,循环后有对应选项再进行赋值
        if (!initControl) {
          control.controlVals.forEach((vals) => {
            vals.dependFields.forEach((dependField) => {
              this.formAttr.forEach((controlAttr) => {
                if (controlAttr.fieldId === dependField.dependfieldid) {
                  controledFieldId.push(controlAttr.fieldId);
                  controledFieldProp.push(controlAttr.prop);
                  this.$set(this.optionList, controlAttr.prop, []);
                }
              });
            });
          });
        }

        // 查找当前值是否有控制规则
        let controlCondition = control.controlVals.find((item) => {
          return item.val === selectVal;
        });
        if (controlCondition === undefined) {
          controledFieldId.forEach((fieldId) => {
            this.formAttr.forEach((controlAttr) => {
              if (controlAttr.fieldId === fieldId) {
                // 清空被控字段值并递归
                if (controlAttr.fieldType === "Q") {
                  this.$set(formData, controlAttr.apiname, []);
                } else {
                  this.$set(formData, controlAttr.apiname, "");
                }
                this.control(formData[controlAttr.apiname], fieldId, formData);
              }
            });
          });

          // control.controlVals.forEach((vals) => {
          //   // 控制值
          //   vals.dependFields.forEach((dependField) => {
          //     this.formAttr.forEach((controlAttr, idx) => {
          //       if (controlAttr.fieldId === dependField.dependfieldid) {
          //         // 清空被控字段值并递归
          //         if (controlAttr.fieldType === 'Q') {
          //           this.$set(formData, controlAttr.apiname, [])
          //         } else {
          //           this.$set(formData, controlAttr.apiname, '')
          //         }
          //         this.control(
          //           formData[controlAttr.apiname],
          //           dependField.dependfieldid,
          //           formData
          //         )
          //       }
          //     })
          //   })
          // })
        } else {
          // 控制选项列表和选项值
          controlCondition.dependFields.forEach((dependField) => {
            this.formAttr.forEach((controlAttr) => {
              if (controlAttr.fieldId === dependField.dependfieldid) {
                let options = [];
                dependField.vals.forEach((element) => {
                  options.push({
                    key: element.deflangval,
                    val: element.val,
                  });
                });
                this.optionList[controlAttr.prop] = options;

                // 若当前选中值不在选项列表中，则清空
                let option = options.find((item) => {
                  return item.val === formData[controlAttr.apiname];
                });
                if (option === undefined) {
                  // 清空被控字段值并递归
                  if (controlAttr.fieldType === "Q") {
                    this.$set(formData, controlAttr.apiname, []);
                  } else {
                    this.$set(formData, controlAttr.apiname, "");
                  }
                  // 如果被控制字段选项中包含字段默认值，将依赖字段的值赋为默认值
                  if (
                    controlAttr.defaultValue &&
                    controlAttr.defaultValue !== ""
                  ) {
                    let option = options.find((item) => {
                      return item.val === controlAttr.defaultValue;
                    });
                    if (option !== undefined) {
                      this.$set(
                        formData,
                        controlAttr.apiname,
                        controlAttr.fieldType === "Q"
                          ? [controlAttr.defaultValue]
                          : controlAttr.defaultValue
                      );
                    }
                  }
                  this.control(
                    formData[controlAttr.apiname],
                    dependField.dependfieldid,
                    formData
                  );
                }
              }
            });
          });
        }

        // 控制条件
        control.controlVals.forEach((vals) => {
          // eslint-disable-next-line no-empty
          if (vals.val === selectVal) {
          }
        });
      }
    },
    // 动态布局规则
    getDynamicLayoutListDetail(layoutId) {
      CommonObjApi.getDynamicLayoutListDetail({
        layoutId: layoutId,
      }).then((res) => {
        // 清空动态布局规则
        this.actionRequireds = [];
        this.actionShowFields = [];
        this.actionShowSections = [];
        this.actionReadonlys = [];
        this.actionConditions = res.data;

        res.data !== null &&
          res.data.forEach((action) => {
            // 重置规则控制字段
            action.mainConditions.forEach((mainCondition) => {
              // 主规则
              this.handleCondition(mainCondition, false);

              this.formAttr.forEach((attr, idx) => {
                // 地址和地理定位字段动态布局返回的是父字段id
                let fieldId = attr.fieldId;
                if (attr.parentType === "AD" || attr.parentType === "LT") {
                  fieldId = attr.parentId;
                }
                if (fieldId === mainCondition.fieldId) {
                  let changeEvent =
                    attr.changeEvent === undefined
                      ? "handleAction"
                      : `${attr.changeEvent},handleAction`;
                  this.$set(this.formAttr[idx], "changeEvent", changeEvent);
                }
              });

              // 二级规则
              mainCondition.dynamicPageConditions &&
                mainCondition.dynamicPageConditions.forEach((dynamicPage) => {
                  this.handleCondition(dynamicPage, false);
                  //
                  dynamicPage.twoLevelConditions.forEach(
                    (twoLevelCondition) => {
                      this.formAttr.forEach((attr, idx) => {
                        // 地址和地理定位字段动态布局返回的是父字段id
                        let fieldIdTwo = attr.fieldId;
                        if (
                          attr.parentType === "AD" ||
                          attr.parentType === "LT"
                        ) {
                          fieldIdTwo = attr.parentId;
                        }
                        if (fieldIdTwo === twoLevelCondition.fieldId) {
                          let changeEvent =
                            attr.changeEvent === undefined
                              ? "handleAction"
                              : `${attr.changeEvent},handleAction`;
                          this.$set(
                            this.formAttr[idx],
                            "changeEvent",
                            changeEvent
                          );
                        }
                      });
                    }
                  );
                });
            });
          });

        // 走规则
        this.handleAction();
      });
    },
    // 重置规则控制字段
    handleCondition(condition, flag) {
      // 设置字段/部分 是否显示/必填 注：部分下有必填字段时不隐藏该部分
      // 当flag为真时，规则中只要有一个条件满足，该字段显示/必填就为真
      // 每个控制字段变化时所有规则重新走一遍
      if (flag) {
        if (condition.action_requireds) {
          condition.action_requireds.forEach((requiredField) => {
            //  如果动态布局不是只读的，判断该字段动态布局是否是必填的，需要结合动态布局是否是必填的，如果是必填的，那么就是必填，只读为false。动态布局优先级永远是大于原布局
            this.actionRequireds.forEach((item) => {
              if (item.fieldId === requiredField.field_id) {
                item.required = item.required || flag;
              }
            });
          });
        }
        if (condition.action_readonlys) {
          condition.action_readonlys.forEach((readonlysField) => {
            this.actionReadonlys.forEach((item) => {
              if (item.fieldId === readonlysField.field_id) {
                item.readonlys = item.readonlys || flag;
              }
            });
          });
        }
        if (condition.action_showFields) {
          condition.action_showFields.forEach((requiredField) => {
            this.actionShowFields.forEach((item) => {
              if (item.fieldId === requiredField.field_id) {
                item.show = item.show || flag;
              }
            });
          });
        }
        if (condition.action_showSections) {
          condition.action_showSections.forEach((requiredField) => {
            this.actionShowSections.forEach((item) => {
              if (item.selectionId === requiredField.section_id) {
                item.showSection = item.showSection || flag;
              }
            });
          });
        }
      } else {
        // 初始化需要去重
        // 字段显示与否
        if (condition.action_showFields) {
          let showFields = [];
          condition.action_showFields.forEach((requiredField) => {
            if (!showFields.includes(requiredField.field_id)) {
              showFields.push(requiredField.field_id);
              this.actionShowFields.push({
                fieldId: requiredField.field_id,
                show: flag,
              });
            }
          });
        }
        // 部分显示与否
        if (condition.action_showSections) {
          let showSections = [];
          condition.action_showSections.forEach((requiredField) => {
            if (!showSections.includes(requiredField.section_id)) {
              showSections.push(requiredField.section_id);
              this.actionShowSections.push({
                sectionName: requiredField.sectionName,
                selectionId: requiredField.section_id,
                showSection: flag,
              });
            }
          });
        }
        // 字段必填与否
        if (condition.action_requireds) {
          let requireds = [];
          condition.action_requireds.forEach((requiredField) => {
            if (!requireds.includes(requiredField.field_id)) {
              requireds.push(requiredField.field_id);
              this.actionRequireds.push({
                fieldId: requiredField.field_id,
                required: flag,
              });
            }
          });
        }
        // 只读字段

        if (condition.action_readonlys) {
          let readonlys = [];
          condition.action_readonlys.forEach((readonlysField) => {
            if (!readonlys.includes(readonlysField.field_id)) {
              readonlys.push(readonlysField.field_id);
              this.actionReadonlys.push({
                fieldId: readonlysField.field_id,
                readonlys: flag,
              });
            }
          });
        }
      }
    },
    /**
     * 判断高级条件是否成立
     * @param {Array} booleanList:条件成立结果组成的数组
     * @param {String} label:高级条件的label，表示高级条件的组合关系
     * 
     */
     isJudgment(booleanList,label){
      let isTrue = false
      if(label){
        // 去掉1 and 
        label = label.slice(5)
        // 替换and和or
        label = label.replace(/and/g,'&&')
        label = label.replace(/or/g,'||')
        // 替换每个条件的执行
        booleanList.forEach((item,index) => {
          let labelIndex = new RegExp((index+2).toString())
          label = label.replace(labelIndex,item)
        })
        isTrue = eval(label)
      }
      return isTrue
    },
    // 走规则
    handleAction() {
      // 清空动态布局规则
      this.actionRequireds = [];
      this.actionShowFields = [];
      this.actionShowSections = [];
      this.actionReadonlys = [];

      this.actionConditions.forEach((action) => {
        // 重置规则控制字段
        action.mainConditions.forEach((mainCondition) => {
          // 主规则
          this.handleCondition(mainCondition, false);

          // 二级规则
          mainCondition.dynamicPageConditions &&
            mainCondition.dynamicPageConditions.forEach((dynamicPage) => {
              this.handleCondition(dynamicPage, false);
            });
        });
      });

      this.actionConditions.forEach((action) => {
        // 循环规则控制字段
        action.mainConditions.forEach((mainCondition) => {
          let judgment = this.judgValue(
            mainCondition.fieldName,
            mainCondition.operator,
            mainCondition.value,
            mainCondition.fieldType
          );
          if (judgment) {
            this.handleCondition(mainCondition, true);
            // 二级规则 - 符合一级规则时才走二级规则
            mainCondition.dynamicPageConditions &&
              mainCondition.dynamicPageConditions.forEach((dynamicPage) => {
                // 二级条件执行
                let judgment = [];
                dynamicPage.twoLevelConditions.forEach((twoLevelCondition) => {
                  let judfment2= this.judgValue(
                      twoLevelCondition.fieldName,
                      twoLevelCondition.operator,
                      twoLevelCondition.value,
                      twoLevelCondition.fieldType
                    );
                    
                  judgment.push(judfment2)
                });
                // 执行高级条件
                if (this.isJudgment(judgment,dynamicPage.label)) {
                  this.handleCondition(dynamicPage, true);
                }
              });
          }
        });
      });

      // 根据规则设置字段显示/隐藏
      this.actionShowFields.forEach((actionShowField) => {
        this.formAttr.forEach((attr) => {
          let fieldId = attr.fieldId;
          if (attr.parentType == "AD" || attr.parentType == "LT") {
            // 地址和地理定位字段时需要对比attr上的父字段
            fieldId = attr.parentId;
          }
          if (fieldId === actionShowField.fieldId) {
            if (actionShowField.show) {
              attr.hidden = false;
            } else {
              attr.hidden = true;
            }
          }
        });
      });

      // 设置部分显示/隐藏
      this.actionShowSections.forEach((actionShowSection) => {
        if (actionShowSection.sectionName) {
          this.formAttr.forEach((attr) => {
            // 控制某部分标题显示/隐藏
            // if (attr.title === actionShowSection.sectionName || attr.groupTitle == actionShowSection.sectionName) {
            if (attr.selectionId === actionShowSection.selectionId) {
              if (actionShowSection.showSection) {
                attr.hidden = false;
                attr.groupShow = true;
              } else {
                // 动态布局下只能设置条件符合时部分显示、字段显示、字段只读、字段必填
                // 如果动态规则条件不成立，原布局部分下有必填字段时不隐藏该部分
                let requiredFields = this.formAttr.find((item) => {
                  return (
                    item.selectionId === actionShowSection.selectionId &&
                    item.required
                  );
                });
                if (requiredFields !== undefined) {
                  attr.hidden = false;
                  attr.groupShow = true;
                } else {
                  attr.hidden = true;
                  attr.groupShow = false;
                }
              }
            }
          });
        }
      });

      // 必须先走字段必填，在走字段只读
      // 根据规则设置字段必填
      this.actionRequireds.forEach((actionRequire) => {
        // 需要判断该字段动态布局是否只读,并且只读条件成立，或者原布局该字段只读
        // 该字段动态布局是否只读,并且只读条件成立
        let flag = this.actionReadonlys.find((item) => {
          return item.fieldId === actionRequire.fieldId && item.readonlys;
        });
        this.formAttr.forEach((attr) => {
          let fieldId = attr.fieldId;
          if (attr.parentType == "AD" || attr.parentType == "LT") {
            // 地址和地理定位字段时需要对比attr上的父字段
            fieldId = attr.parentId;
          }
          if (fieldId === actionRequire.fieldId) {
            // 判断该字段动态布局或者原布局是否只读
            if (flag == undefined && attr.editCopy) {
              // 只读不成立
              // 判断动态必填或者原布局必填是否成立
              if (attr.requiredCopy || actionRequire.required) {
                attr.rules = [
                  {
                    required: true,
                    message: `${this.$i18n.t("label_tabpage_placeenterz")} ${
                      attr.fieldLabel
                    }`,
                    trigger: "blur",
                    // 请输入
                  },
                ];
                // 改变只读,设置可编辑
                attr.edit = true;
              } else {
                // 该字段无规则
                // 清空必填，设置可编辑
                attr.edit = true;
                attr.rules = [];
              }
            }
          }
        });
      });
      //                 控制字段只读
      this.actionReadonlys.forEach((actionReadonly) => {
        this.formAttr.forEach((attr) => {
          let fieldId = attr.fieldId;
          if (attr.parentType == "AD" || attr.parentType == "LT") {
            // 地址和地理定位字段时需要对比attr上的父字段
            fieldId = attr.parentId;
          }
          if (fieldId === actionReadonly.fieldId) {
            // 原字段只读或者动态规则只读条件成立
            if (!attr.editCopy || actionReadonly.readonlys) {
              attr.edit = false;
              attr.rules = [];
            } else {
              // 只读不成立，判断原布局或动态布局必填
              let flag = this.actionRequireds.find((item) => {
                return item.fieldId === actionReadonly.fieldId && item.required;
              });
              if (attr.requiredCopy || flag !== undefined) {
                attr.rules = [
                  {
                    required: true,
                    message: `${this.$i18n.t("label_tabpage_placeenterz")} ${
                      attr.fieldLabel
                    }`,
                    trigger: "blur", // 请输入
                  },
                ]; // 改变只读,设置可编辑
                attr.edit = true;
              } else {
                // 该字段没有规则
                attr.edit = true;
                attr.rules = [];
              }
            }
          }
        });
      });
      this.$refs.baseForm && this.$refs.baseForm.resetRules();
    },
    // 判断值
    judgValue(field, operator, value, fieldType) {
      //
      if (
        this.$refs.baseForm.formData[field] !== undefined &&
        this.$refs.baseForm.formData[field] !== null
      ) {
        if (fieldType === "Q") {
          //  以后组件值是数组的都要走这里
          //  多选列表选中的值
          let valueAry = this.$refs.baseForm.formData[field];
          // 主字段设置的数组
          let actionAry = value.split(",");
          // 多选列表选中值中被主字段设置的数据包含的值
          let filterAry = actionAry.filter((item) => {
            return valueAry.indexOf(item) > -1;
          });
          switch (operator) {
            case "e":
              // 等于(全包含，并且数组长度都一样)
              return (
                filterAry.length === actionAry.length &&
                filterAry.length === valueAry.length
              );
            case "n":
              // 不等于
              return !(
                filterAry.length === actionAry.length &&
                filterAry.length === valueAry.length
              );

            case "c":
              // 包含（只要有其中一个条件就成立）
              return filterAry.length > 0;
            case "k":
              // 不包含
              return filterAry.length === 0;
          }
        } else {
          // 判断字段类型
          fieldType = fieldType ? INPUTTYPE[fieldType] : fieldType;
          // 获取表单中该字段的值
          let formDateField = this.$refs.baseForm.formData[field].toString();

          if (fieldType === "date" || fieldType === "datetime") {
            // 处理日期、日期时间字段
            formDateField = new Date(formDateField).getTime().toString();
            value = new Date(value).getTime().toString();
          }

          if (fieldType === "time") {
            // 处理时间字段
            let formField = formDateField.split(":");
            let valueField = value.split(":");
            formDateField = (
              Number(formField[0] ? formField[0] : "") * 3600 +
              Number(formField[1] ? formField[1] : "") * 60 +
              Number(formField[2] ? formField[2] : "")
            ).toString();

            value = (
              Number(valueField[0] ? valueField[0] : "") * 3600 +
              Number(valueField[1] ? valueField[1] : "") * 60 +
              Number(valueField[2] ? valueField[2] : "")
            ).toString();
          }
          switch (operator) {
            case "e":
              // 等于
              return formDateField === value;
            case "n":
              // 不等于
              return formDateField !== value;
            case "l":
              // 小于
              return Number(formDateField) < Number(value);
            case "g":
              // 大于
              return Number(formDateField) > Number(value);
            case "m":
              // 小于或等于
              return Number(formDateField) <= Number(value);
            case "h":
              // 大于或等于
              return Number(formDateField) >= Number(value);
            case "c":
              // 包含
              return formDateField.includes(value);
            case "k":
              // 不包含
              return !formDateField.includes(value);
            case "s":
              // 起始字符
              return formDateField.indexOf(value) === 0;
          }
        }
      } else {
        // 如果是不包含、不等于返回true，其他false
        let flag = operator === "n" || operator === "k";
        // 如果value为空，此时operator是e（等于）返回为true
        if(operator === 'e' && !value && value!== 0){
          flag = true
        }
        return flag;
      }
    },
    // 表单添加属性
    addFormAttr(group, colNum, attr, isChildren, position, parentAttr, type) {
      if (position !== undefined) {
        this.$set(attr, "position", position);
      }
      // 单独存储备用原布局中的required和edit,动态布局设置规则的时候使用
      attr.requiredCopy = attr.required;
      attr.editCopy = attr.edit;
      if (attr.required && attr.edit) {
        if (this.objectApi === "Contact" && this.contactes === "contactsNew") {
          if (attr.apiname === "loginname") {
            attr.rules = [
              {
                required: attr.required,
                message: `${this.$i18n.t(
                  "label_tabpage_placeenterz"
                )} ${this.$i18n.t("label.email")}`,
                trigger: "blur",
                // 请输入
              },
            ];
          } else {
            attr.rules = [
              {
                required: attr.required,
                message: `${this.$i18n.t("label_tabpage_placeenterz")} ${
                  attr.fieldLabel || attr.label
                }`,
                trigger: "blur",
                // 请输入
              },
            ];
          }
        } else {
          attr.rules = [
            {
              required: attr.required,
              message: `${this.$i18n.t("label_tabpage_placeenterz")} ${
                attr.fieldLabel || attr.label
              }`,
              trigger: "blur",
              // 请输入
            },
          ];
        }
      }
      attr.prop =
        isChildren && isChildren !== undefined ? attr.name : attr.apiname;
      if (
        parentAttr &&
        (parentAttr.fieldType == "AD" || parentAttr.fieldType == "LT")
      ) {
        // 如果父字段是地址和地理定位字段，存储父字段fieldId
        attr.parentId = parentAttr.fieldId;
        attr.parentType = parentAttr.fieldType;
      }
      // 给字段设置默认值
      if (this.dialogAttr.type !== "EDIT" && this.dialogAttr.type !== "COPY") {
        // 非查找/查找多选字段
        if (attr.defaultValue && attr.defaultValue !== "") {
          attr.value = attr.defaultValue;
        }
        if (attr.apiname == "totalamount") {
          attr.value = Number(this.totalPrice).toFixed(2);
        }
      }
      if (
        attr.fieldType !== "AD" &&
        attr.fieldType !== "LT" &&
        !attr.children
      ) {
        this.attrIndex += colNum === 1 ? 0 : 1;
        this.$set(attr, "attrIndex", this.attrIndex);
        this.$set(
          attr,
          "label",
          attr.fieldLabel === undefined ? attr.label : attr.fieldLabel
        );
        this.$set(
          attr,
          "type",
          INPUTTYPE[attr.fieldType === undefined ? attr.type : attr.fieldType]
        );

        // 百分数字段字数长度限制
        if (attr.fieldType === "P") {
          attr.precision = Number(attr.decimalPlaces);
          attr.valueInterval = {
            min: 0 - Math.pow(10, attr.length - attr.decimalPlaces - 1) + 1,
            max: Math.pow(10, attr.length - attr.decimalPlaces - 1) - 1,
          };
        }
        // 图片显示
        if (
          attr.fieldType === "IMG" &&
          attr.expressionType !== "url" &&
          attr.value !== undefined &&
          this.dialogAttr.type === "EDIT"
        ) {
          if (attr.value !== "") {
            attr.data = [
              {
                name: "",
                url: `${this.userUrl}/querysome.action?m=viewImg&fileid=${attr.value}&binding=${this.token}`,
              },
            ];
          } else {
            attr.data = [];
          }
        }
        // 评分新建时评分
        if (attr.type === "score") {
          attr.value =
            this.dialogAttr.type !== "EDIT"
              ? attr.value
                ? Number(attr.value)
                : 0
              : Number(attr.value);
        }
        // 选项列表和选项列表多选
        if (attr.type === "select" || attr.type === "multi-select") {
          let options = [];
          // 记录类型可改
          // if (attr.prop === "recordtype" && (this.dialogAttr.type === "EDIT" || this.dialogAttr.type === "COPY")) {
          //   this.recordType = attr.id
          // } else if (attr.prop === "recordtype" && this.dialogAttr.type === "NEW") {
          //   // 新建时若有记录类型字段，设置当前记录类型到表单中
          //   this.$set(attr, "value", this.recordType)
          // }

          // 判断如果有 国家或地区(以详细地址字段名+00结尾) 字段,获取国家或地区选项
          if (
            attr.name &&
            attr.name !== "name00" &&
            attr.name.slice(attr.name.length - 2) === "00"
          ) {
            this.getCountrySelectValue(attr.name);
          } else {
            attr.data &&
              attr.data.length > 0 &&
              attr.data.forEach((item) => {
                options.push({
                  key: item.value,
                  val: item.id,
                });
              });

            this.optionList[attr.prop] = options;
          }

          // 设置表单记录类型选项
          if (attr.prop === "recordtype") {
            this.optionList[attr.prop] = [
              {
                key: attr.value,
                val: attr.id,
              },
            ];
          }
          if (attr.type === "multi-select") {
            attr.value =
              // 无
              attr.value === "" ||
              attr.value === null ||
              attr.value === undefined
                ? []
                : attr.value.split(";");
          }
        } else if (
          attr.type === "remote-select" ||
          attr.type === "remote-multi-select"
        ) {
          // 赋值默认值方法
          // 查找多选字段选项设置
          if (
            attr.fieldType === "MR" &&
            attr.value &&
            attr.value.length !== 0 &&
            attr.data &&
            attr.data.length > 0 &&
            attr.data[0].value !== ""
          ) {
            let multiOptions = [];
            let labels = attr.data[0].value.split(";");
            let values = attr.data[0].id.split(";");

            for (let index = 0; index < labels.length; index++) {
              multiOptions.push({
                label: labels[index],
                value: values[index],
              });
            }
            this.$set(this.optionList, attr.prop, multiOptions);
            // 无
            attr.value =
              (attr.value === attr.data[0].value) === ""
                ? []
                : attr.value.split(";");
          } else {
            // 查找字段选项设置
            let multiOptions = [];
            //启用外部用户的联系人
            if (attr.apiname === "usercontact") {
              multiOptions.push({
                label: attr.usercontactName,
                value: attr.value,
              });
            } else {
              // 查找字段对应的记录有对应的value时才展示

              if (
                attr.value !== "" &&
                attr.data &&
                attr.data.length > 0 &&
                attr.data[0].value !== ""
              ) {
                multiOptions.push({
                  label: attr.data[0].value,
                  value: attr.data[0].id,
                });
              } else {
                if (
                  attr.apiname === "marketsea" &&
                  this.dialogAttr.type === "NEW" &&
                  this.isClientPoolEditStatus === false
                ) {
                  // 公海池的特殊字段，客户公海池和潜在客户公海池新建时才默认赋值
                  // 公海池的新建时 选中默认的当前的公海池 option
                  // this.objectApi = this.$route.params.objectApi;
                  if (
                    this.objectApi !== "Lead" &&this.objectApi !== "cloudcclead" 
                  ) {
                    multiOptions.push({
                      label: this.currentClientPoolSelectObj.name,
                      value: this.currentClientPoolSelectObj.id,
                    });
                    attr.value = this.currentClientPoolSelectObj.id;
                  }
                } else {
                  attr.value = attr.fieldType === "MR" ? [] : "";
                }
              }
            }

            // 创建人和最后修改人选项
            if (
              attr.apiname === "createbyid" ||
              attr.apiname === "lastmodifybyid"
            ) {
              multiOptions.push({
                value: attr.value,
                label: attr[`${attr.apiname}Name`],
              });
            }
            this.$set(this.optionList, attr.prop, multiOptions);
          }

          // 新建时设置默认值和选项
          if (
            this.dialogAttr.type !== "EDIT" &&
            attr.defaultValue &&
            attr.defaultValue !== "" &&
            attr[`${attr.apiname}Name`] &&
            attr[`${attr.apiname}Name`] !== "" &&
            (attr.fieldType === "Y" || attr.fieldType === "M")
          ) {
            let option = [
              {
                label: attr[`${attr.apiname}Name`],
                value: attr.defaultValue,
              },
            ];
            this.$set(attr, "value", attr.defaultValue);
            this.$set(this.optionList, attr.prop, option);
          }
          // attr.readonly = true;
          // 新增时 默认价格手册  默认客户
          if (attr.prop === "accountid" && this.dialogAttr.type === "NEW") {
            attr.value = this.accountInfo.id;
            let options = [
              {
                label: this.accountInfo.name,
                value: this.accountInfo.id,
              },
            ];

            this.$set(this.optionList, attr.prop, options);
          }
          // pricebook2id
          if (attr.prop === "pricebook2id" && this.dialogAttr.type === "NEW") {
            attr.value = this.listDate[0].pricebook;
            let options = [
              {
                label: this.priceBookName,
                value: this.listDate[0].pricebook,
              },
            ];
            this.$set(this.optionList, attr.prop, options);
          }
          // 新增时默认 所有人 为当前登录用户
          if (
            attr.prop === "ownerid" &&
            (this.dialogAttr.type === "NEW" || this.dialogAttr.type === "COPY")
          ) {
            attr.value = this.currentUserInfo.id;
            let options = [
              {
                label: this.currentUserInfo.name,
                value: this.currentUserInfo.id,
              },
            ];
            this.$set(this.optionList, attr.prop, options);
          }
        } else if (attr.type === "number") {
          if (Number(attr.decimalPlaces) !== 0) {
            attr.precision = Number(attr.decimalPlaces);
          }
          if (attr.fieldLength !== undefined) {
            attr.valueInterval = {
              min:
                0 - Math.pow(10, attr.fieldLength - attr.decimalPlaces - 1) + 1,
              max: Math.pow(10, attr.fieldLength - attr.decimalPlaces - 1) - 1,
            };
          } else {
            attr.valueInterval = {
              min: 0 - Math.pow(10, attr.length - attr.decimalPlaces - 1) + 1,
              max: Math.pow(10, attr.length - attr.decimalPlaces - 1) - 1,
            };
          }
        } else if (attr.type === "checkbox") {
          attr.value = attr.value === "true" ? true : false;
        }
        attr.width = colNum === 1 ? "100%" : "50%";
        attr.colNum = colNum;
        // colNum === 1 || attr.type === "kindeditor" ? "100%" : "50%";

        attr.groupTitle = group.title;
        attr.selectionId = group.selectionId;
        attr.groupShow = true;
        //判断是否为地址字段子级
        if (type == "address") {
          this.addrArr.push(attr);
        } else {
          this.formAttr.push(attr);
        }
        // 标签和新建时自动编号字段不展示，空位留出
        // if (
        //   attr.apiname !== "cloudcctag" &&
        //   !(this.dialogAttr.type !== "EDIT" && attr.fieldType === "V")
        // ) {
        //   this.formAttr.push(attr);
        // } else {
        //   this.formAttr.push({
        //     width: "50%",
        //     colNum: 2,
        //     attrIndex: this.attrIndex,
        //     type: "noData",
        //     hidden: false,
        //     groupShow: true,
        //   });
        // }
      } else {
        if (attr.fieldType === "AD") {
          attr.isContrField = true;
          this.addrArr = [];
          // 地址和地理定位特殊处理
          attr.groupShow = true;
          attr.label = attr.fieldLabel;
          attr.children.forEach((item) => {
            if (
              (item.name && item.name.slice(-9) === "longitude") ||
              (item.name && item.name.slice(-8) === "latitude")
            ) {
              this.$set(item, "type", "noData");
              this.$set(item, "hidden", true);
            }
            this.addFormAttr(
              group,
              colNum,
              item,
              true,
              position,
              attr,
              "address"
            );
          });
          attr.children = this.addrArr;
          this.formAttr.push(attr);
        } else {
          attr.children.forEach((item) => {
            if (
              (item.name && item.name.slice(-9) === "longitude") ||
              (item.name && item.name.slice(-8) === "latitude")
            ) {
              this.$set(item, "type", "noData");
              this.$set(item, "hidden", true);
            }
            //订单发票新建时地址字段只读保存后自动赋值
            // if((this.objectApi == 'cloudccorder' || this.objectApi == 'Invoices') && this.dialogAttr.type == "NEW"){
            // this.$set(item, 'placeholder', this.$t('c566'));
            // this.$set(item, 'edit', false);
            // }
            this.addFormAttr(group, colNum, item, true, position, attr);
          });

          this.attrIndex -= attr.children.length - 1;
        }
      }
      if (attr.isContrField) {
        let changeEvent =
          attr.changeEvent === undefined
            ? "control"
            : `${attr.changeEvent},control`;
        this.$set(attr, "changeEvent", changeEvent);
      }
      // 如果为事件或任务类型，默认分配当前登录用户，特殊处理名称及相关项
      if (
        this.objId === "event" ||
        this.objId === "task" ||
        this.objectApi === "Event" ||
        this.objectApi === "Task"
      ) {
        // 如果操作为新建，填充部分默认值
        if (this.dialogAttr.type === "NEW" || this.dialogAttr.type === "COPY") {
          // 被分配人字段重新复制
          if (attr.prop === "belongtoid") {
            attr.fieldType = "MR";
            attr.type = "remote-multi-select";
            // 使用日历视图传过来的值
            if (this.calendarUser.userId) {
              attr.value = [this.calendarUser.userId];
              this.optionList.belongtoid = [
                {
                  value: this.calendarUser.userId,
                  label: this.calendarUser.userName,
                },
              ];
            } else {
              attr.value = [this.currentUserInfo.id];
              this.optionList.belongtoid = [
                {
                  value: this.currentUserInfo.id,
                  label: this.currentUserInfo.name,
                },
              ];
            }
            this.getRelatedItem();
          } else if (attr.prop === "begintime") {
            attr.value = this.eventDate.start || this.getHour().start;
          } else if (attr.prop === "endtime") {
            attr.value = this.eventDate.end || this.getHour().end;
          } else if (attr.prop === "whoid") {
            // 判断对象是否事件/任务
            if (this.objectApies !== "") {
              if (
                this.objectApies === "Lead" ||
                this.objectApies === "cloudcclead" ||
                this.objectApies === "Contact"
              ) {
                if (this.bigTitle.length !== 0) {
                  let child = {};
                  let obj = {
                    whoname: this.bigTitle[0].fieldValue,
                    whoobj: this.objectNames,
                  };
                  child = obj;
                  attr.child = child;
                  attr.value = this.recordIds;
                }
              }
            }

            // 判断是否主页事件、任务等要赋值的对象
            else if (this.homeNweObjid !== "") {
              if (
                this.homeNweObjid === "Lead" ||
                this.homeNweObjid === "cloudcclead" ||
                this.homeNweObjid === "Contact"
              ) {
                if (this.homeNweName) {
                  let child = {};
                  let obj = {
                    whoname: this.homeNweName,
                    whoobj: this.homeContactName,
                  };
                  child = obj;
                  attr.child = child;
                  attr.value = this.homeNweId;
                }
              }
            }

            // 来自详情页跳转日历新建事件，回显处理
            else if (this.calendarData.id) {
              if (
                this.calendarData.prefix === "003" ||
                this.calendarData.prefix === "004"
              ) {
                let child = {};
                let obj = {
                  whoname: this.calendarData.name,
                  whoobj: this.calendarData.tabName,
                };
                child = obj;
                attr.child = child;
                attr.value = this.calendarData.id;
              }
            }
          } else if (attr.prop === "relateid") {
            if (this.objectApies !== "") {
              if (
                this.objectApies !== "Lead" &&
                this.objectApies !== "cloudcclead" &&
                this.objectApies !== "Contact"
              ) {
                if (this.bigTitle.length !== 0) {
                  let child = {};
                  let obj = {
                    relatename: this.bigTitle[0].fieldValue,
                    relateobj: this.objectNames,
                  };
                  child = obj;
                  attr.child = child;
                  attr.value = this.recordIds;
                }
              }
            } else if (this.homeNweObjid !== "") {
              if (
                this.homeNweObjid !== "Lead" &&
                this.homeNweObjid !== "cloudcclead" &&
                this.homeNweObjid !== "Contact"
              ) {
                if (this.homeNweName) {
                  let child = {};
                  let obj = {
                    relatename: this.homeNweName,
                    relateobj: this.homeContactName,
                  };
                  child = obj;
                  attr.child = child;
                  attr.value = this.homeNweId;
                }
              }
            } else if (this.calendarData.id) {
              if (
                this.calendarData.prefix !== "003" &&
                this.calendarData.prefix !== "004"
              ) {
                attr.child = {
                  relatename: this.calendarData.name,
                  relateobj: this.calendarData.tabName,
                };
                attr.value = this.calendarData.id;
              }
            }
          }
        } else {
          if (attr.prop === "belongtoid") {
            attr.fieldType = "MR";
            attr.type = "remote-multi-select";
            attr.value = [attr.value];
            this.getRelatedItem();
          }
        }
      }
      // 判断为轮班，处理传入时间和服务区域、服务资源
      if (this.objectApi === "Shift") {
        if (this.dialogAttr.type === "NEW" && this.shiftData.date) {
          if (attr.prop === "starttime") {
            attr.value = this.shiftData.date.startTime || "";
          } else if (attr.prop === "endtime") {
            attr.value = this.shiftData.date.endTime || "";
          } else if (attr.prop === "serviceresourceid") {
            // 服务资源
            attr.value = this.shiftData.resouce.titleId || "";
            this.optionList.serviceresourceid = [
              {
                value: this.shiftData.resouce.titleId || "",
                label: this.shiftData.resouce.title || "",
              },
            ];
          } else if (attr.prop === "serviceterritoryid") {
            // 服务区域
            attr.value = this.shiftData.area.id || "";
            this.optionList.serviceterritoryid = [
              {
                value: this.shiftData.area.id || "",
                label: this.shiftData.area.name || "",
              },
            ];
          }
        }
      }
    },
    // 获取国家或地区选项列表值
    getCountrySelectValue(filedName) {
      CommonObjApi.getSelectValue({ fieldId: "country" }).then((res) => {
        if (res.result && res.returnCode === "1") {
          let options = [];
          res.data.forEach((item) => {
            options.push({
              val: item.codekey,
              key: item.codevalue,
            });
          });
          this.$set(this.optionList, filedName, options);
        } else {
          this.$message.error(res.returnInfo);
        }
      });
    },
    notZhucongfield() {
      this.isZhucongfield = false;
    },
    // 查找/查找多选
    remoteSearch(apiname, item, num, ind, relatedObjPrefix) {
      //
      this.idx = num;
      this.ind = ind;
      this.rowIndex = num + 1;
      this.apiname = apiname;
      this.fieldId = item.id;
      this.isZhucongfield = true;
      this.showSearchTable = true;
      this.relevantPrefix = item.lookupObj;
      this.relevantObjId = item.lookupObjid;
      this.relatedObjPrefix = relatedObjPrefix;
      this.checked = item.type === "remote-multi-select" ? true : false;

      this.$nextTick(() => {
        this.$refs.searchTable.page = 1;
        this.$refs.searchTable.pageObj.dataList = [];
        this.$refs.searchTable.searchConditions = "";
        this.$refs.searchTable.init();
      });
    },
    // 设置查找筛选条件
    setFieldReltaion(filterFieldIds) {
      let values = [];
      let dataId =
        this.dataLists[this.ind][this.rowIndex - 1].id === undefined
          ? ""
          : this.dataLists[this.ind][this.rowIndex - 1].id.value;
      values.push(dataId);
      for (let reltaion in filterFieldIds) {
        this.$refs.EditableCell.forEach((editableCell) => {
          if (
            editableCell.lineIndex === this.rowIndex - 1 &&
            editableCell.fieldId === filterFieldIds[reltaion].fieldid
          ) {
            if (Array.isArray(editableCell.editValue)) {
              values.push(editableCell.editValue.join(";"));
            } else {
              values.push(editableCell.editValue);
            }
          }
        });
      }
      this.$refs.searchTable.filterConditionVals = values;
    },
    closeSearchTable() {
      this.showSearchTable = false;
    },
    // 定位到非必填位置
    verifyJumpFn(object, type) {
      let dom = null;
      if (type) {
        for (let i in object) {
          dom = this.$refs.baseForm.$refs[i];
          if (Object.prototype.toString.call(dom) !== "[object Object]") {
            //这里是针对遍历的情况（多个输入框），取值为数组
            dom = dom[0];
            break;
          }
        }
      } else {
        dom = object;
      }
      dom.$el.scrollIntoView(false);
    },
    // 新增/修改保存
    save(flag) {
      if (this.subordinateFields.length > 0) {
        this.savemessages();
        if (!this.validRelatedObj) {
          // 请检查必填项
          this.$message.warning(
            this.$i18n.t("label.batchadd.newbatchpage.save.emptytext")
          );
          this.verifyJumpFn(this.nullElement, false);
          return;
        }
        if (this.isRepeatTime) {
          // 提示有重复
          this.$message.warning(this.$i18n.t("label.Time.slot.overlap"));
          return;
        }
      }
      this.$refs.baseForm.$refs.elForm.validate((valid) => {
        if (valid) {
          // 保存按钮loading
          this.isSaveBtnLoadin = true;
          let dataList = [];
          let data = Object.assign({}, this.$refs.baseForm.formData);
          // 数字字段特殊处理
          for (const key in data) {
            this.formAttr.forEach((attr) => {
              if (
                attr.prop === key &&
                data[key] === undefined &&
                (attr.type === "number" || attr.type === "percent-number")
              ) {
                data[key] = "";
              }
            });
          }
          delete data.undefined;

          // 潜在客户和个案使用有效的分配规则进行分配
          if (
            this.objectApi === "Lead" ||
            this.objectApi === "cloudcclead" ||
            this.objectApi === "cloudcccase"
          ) {
            data.case_assiGNment = this.validRule ? "true" : "false";
          }

          for (const key in data) {
            // 修改查找多选和选项列表数据格式
            if (Array.isArray(data[key])) {
              data[key] = data[key].join(";");
              // 无
            } else if (
              data[key] === `--${this.$i18n.t("label.data.newtemplate.null")}--`
            ) {
              this.formAttr.forEach((attr) => {
                if (
                  attr.prop === key &&
                  (attr.type === "select" ||
                    attr.type === "multi-select" ||
                    attr.type === "remote-select" ||
                    attr.type === "remote-multi-select")
                ) {
                  data[key] = "";
                }
              });
            } else if (data[key] !== undefined) {
              // 所有数据均变为字符串保存
              if (data[key] !== undefined && data[key] !== null) {
                data[key] = data[key].toString();
              } else {
                data[key] = "";
              }
            } else if (data[key] === undefined) {
              this.formAttr.forEach((attr) => {
                if (attr.prop === key && attr.fieldType === "P") {
                  data[key] = "";
                }
              });
            }
          }
          if (
            this.dialogAttr.type !== "NEW" &&
            this.dialogAttr.type !== "COPY"
          ) {
            data.id = this.id;
          } else {
            // 新增时，保存记录 记录类型保证传参

            if (
              !("recordtype" in data) &&
              this.recordType !== null &&
              this.recordType !== "null"
            ) {
              this.$set(data, "recordtype", this.recordType);
            }
          }
          // 复制时将记录id、创建人、最后修改人字段清空
          if (this.dialogAttr.type === "COPY") {
            delete data.id;
            delete data.createbyid;
            delete data.lastmodifybyid;
          }
          // if (this.objectName === "记录电话") {
          //   data.taskType = "记录电话";
          // }
          //判断是否是联系人，且是否为联系人第一次启用
          if (
            this.objectApi === "Contact" &&
            this.contactes === "contactsNew"
          ) {
            delete data.id;
            let params = data;
            getPartnerSavePartnerUser(params)
              .then((res) => {
                if (res.result) {
                  this.$emit("Refreshes", "true");
                  this.cancel();
                  this.contactes = "";
                } else {
                  this.$message.error(res.returnInfo);
                  this.cancel();
                }
              })
              .catch(() => {
                this.isSaveBtnLoadin = false;
              });
            return;
          }
          dataList.push(data);
          let that = this;
          // 服务云_服务资源校验规则
          // relatedrecordid 用户
          // resourcetype: "班组""调度员""技术人员"
          // servicecrewid 服务班组
          if (that.objectApi === "ServiceResource") {
            // 资源类型 为班组时
            if (dataList[0].resourcetype === "班组") {
              if (dataList[0].servicecrewid) {
                dataList[0].relatedrecordid = "";
              } else if (dataList[0].relatedrecordid) {
                this.$message.error(
                  this.$i18n.t("label.service.resource.uesr.tip")
                );
                this.isSaveBtnLoadin = false;
                return false;
              }
            } else if (dataList[0].resourcetype !== "班组") {
              if (dataList[0].relatedrecordid) {
                dataList[0].servicecrewid = "";
              } else if (dataList[0].servicecrewid) {
                this.$message.error(
                  this.$i18n.t("label.service.resource.group.tip")
                );
                this.isSaveBtnLoadin = false;
                return false;
              }
            }
          }

          // 判断结束时间是否大于开始时间
          if (
            (dataList[0].begintime && dataList[0].endtime) ||
            (dataList[0].start_date && dataList[0].end_date)
          ) {
            // eslint-disable-next-line no-inner-declarations
            function tab(date1, date2) {
              let oDate1 = new Date(date1);
              let oDate2 = new Date(date2);
              if (oDate1.getTime() > oDate2.getTime()) {
                // 结束日期不得小于开始日期
                that.$message.warning(
                  that.$i18n.t("label.weixin.end.later.start")
                );
                that.isSaveBtnLoadin = false;
                return false;
              } else {
                return true;
              }
            }
            if (
              !tab(dataList[0].begintime, dataList[0].endtime) ||
              !tab(dataList[0].start_date, dataList[0].end_date)
            ) {
              return false;
            }
          }
          // 项目管理系统下，fieldType为c即金额类添加验证
          if (
            this.$store.state.home.homeApplicationId === "ace2020D253B3984Or6Q"
          ) {
            let moneyFlag = true,
              hourFlag = true,
              realHourFlag = true,
              moneyName = "",
              hourName = "",
              realHourName = "";
            this.formAttr.forEach((item) => {
              if (item.fieldType && item.fieldType === "c") {
                if (
                  dataList[0][item.apiname] &&
                  dataList[0][item.apiname].length > 10
                ) {
                  moneyName = item.fieldLabel;
                  moneyFlag = false;
                  this.isSaveBtnLoadin = false;
                }
              }
              //项目管理下任务资源工时不能超过24小时的验证
              if (
                item.apiname === "daily_working_hours" &&
                (Number(dataList[0][item.apiname]) > 24 ||
                  Number(dataList[0][item.apiname]) <= 0)
              ) {
                hourName = item.fieldLabel;
                hourFlag = false;
                this.isSaveBtnLoadin = false;
              }
              //项目管理下实际工作清单中 实际工作时长不能超过24小时的验证
              if (
                item.apiname === "working_hours_billing" &&
                (Number(dataList[0][item.apiname]) > 24 ||
                  Number(dataList[0][item.apiname]) <= 0)
              ) {
                realHourName = item.fieldLabel;
                realHourFlag = false;
                this.isSaveBtnLoadin = false;
              }
            });
            if (!moneyFlag) {
              // 最多输入10位
              this.$message.warning(
                `${moneyName}` +
                  this.$i18n.t("label.projectManagement.enter.up.to.10.digits")
              );
              return;
            }
            if (!hourFlag) {
              this.$message.warning(
                `${hourName}` +
                  this.$i18n.t("label.projectManagement.positive.integer")
              ); //请输入0-24之间的正整数
              return;
            }
            if (!realHourFlag) {
              this.$message.warning(
                `${realHourName}` +
                  this.$i18n.t("label.projectManagement.positive.integer")
              ); //请输入0-24之间的正整数
              return;
            }
          }
          if (this.objectApi == "CloudccKArticle") {
            dataList[0].recordtype = this.recordType;
          }
          dataList[0].paymentstatus = "未回款";
          // dataList[0].status = '草稿'
          dataList[0].status =
            this.$store.state.userInfoObj.countryCode == "CN" ||
            this.$store.state.userInfoObj.countryCode == ""
              ? "草稿"
              : "Draft";
          dataList[0].totalamount = dataList[0].totalamount.replace(/,/g, "");
          dataList[0].pricebook2id = this.listDate[0].pricebook; //价格手册保存
          //判断当前新建是否为新建订单或发票页面再进行客户地址新建
          if (this.newCreate == "newCreate") {
            this.$Bus.$emit("deliver-customer-address", dataList);
          }
          let params = {
            objectApi: this.objectApi,
            data: JSON.stringify(dataList),
            marketsea: this.pageFlag === "clientPool" ? "true" : "false", //true是公海池新建记录，false不是
          };
          //项目管理在任务列表编辑子任务保存改变子任务的objectApi
          if (
            this.$route.path.substring(this.$route.path.length - 3) == "p03"
          ) {
            if (dataList[0].their_task) {
              params.objectApi = "cloudccSubtask";
            }
          }
          if (
            this.dialogAttr.type == "NEW" &&
            params.objectApi == "CloudccKArticle"
          ) {
            params.new_article_type = "create";
          }
          // 作为草稿编辑
          if (
            this.dialogAttr.type == "COPY" &&
            params.objectApi == "CloudccKArticle"
          ) {
            // knowledgearticlesApi.asDraftEdit(params).then(() => {});
          } else {
            CommonObjApi.save(params)
              .then((res) => {
                this.isSaveBtnLoadin = false;
                if (
                  res.data &&
                  res.data[0] &&
                  res.data[0].isSaveSuccess === "true"
                ) {
                  // 群策卡片
                  if (
                    res.data[0].cardValue &&
                    res.data[0].cardValue.fieldsList.length > 0
                  ) {
                    let fieldsArr = [];
                    res.data[0].cardValue.fieldsList.forEach((element) => {
                      if (fieldsArr.length < 5) {
                        if (element.fieldType == "B") {
                          if (element.newField == "true") {
                            element.newField =
                              this.$i18n.t("label_tabpage_yesz");
                            element.oldField = this.$i18n.t(
                              "label.checkbox.false"
                            );
                          } else if (element.oldField == "true") {
                            element.newField = this.$i18n.t(
                              "label.checkbox.false"
                            );
                            element.oldField =
                              this.$i18n.t("label_tabpage_yesz");
                          }
                        }
                        fieldsArr.push(element);
                      }
                    });
                    detailSendCard(res.data[0].cardValue.groupId, {
                      title: this.$i18n.t(
                        "vue_label_commonobjects_detail_record_changes"
                      ), //字段记录变更
                      recordId: res.data[0].cardValue.id,
                      type: "TRACK_CARD",
                      body: fieldsArr,
                      changer: localStorage.getItem("username"),
                    });
                  }
                  if (res.data[0].cardList) {
                    let self = this;
                    for (let i = 0; i < res.data[0].cardList.length; i++) {
                      (function (i) {
                        setTimeout(async () => {
                          let fieldsList = [];
                          if (
                            self.objectApi == "Event" ||
                            self.objectApi == "Task"
                          ) {
                            res.data[0].cardList[i].fieldsList.forEach(
                              (item) => {
                                if (
                                  item.fieldValue &&
                                  item.fieldValue != "true" &&
                                  item.fieldValue != "false" &&
                                  item.fieldValue != "0" &&
                                  item.fieldValue != "1" &&
                                  item.filedName != "id"
                                ) {
                                  if (self.objectApi == "Task") {
                                    switch (item.fieldName) {
                                      case "subject":
                                        fieldsList[0] = item;
                                        break;
                                      case "remark":
                                        fieldsList[1] = item;
                                        break;
                                      case "belongtoid":
                                        fieldsList[2] = item;
                                        break;
                                      case "expiredate":
                                        fieldsList[3] = item;
                                        break;
                                      case "status":
                                        fieldsList[4] = item;
                                        break;
                                    }
                                  } else if (self.objectApi == "Event") {
                                    switch (item.fieldName) {
                                      case "subject":
                                        fieldsList[0] = item;
                                        break;
                                      case "remark":
                                        fieldsList[1] = item;
                                        break;
                                      case "belongtoid":
                                        fieldsList[2] = item;
                                        break;
                                      case "begintime":
                                        fieldsList[3] = item;
                                        break;
                                      case "endtime":
                                        fieldsList[4] = item;
                                        break;
                                    }
                                  }
                                }
                              }
                            );
                            fieldsList = fieldsList.filter((element) => {
                              return element != undefined;
                            });
                            if (fieldsList.length < 5) {
                              res.data[0].cardList[i].fieldsList.forEach(
                                (nextElement) => {
                                  if (self.objectApi == "Task") {
                                    if (
                                      fieldsList.length < 5 &&
                                      nextElement.fieldName != "subject" &&
                                      nextElement.fieldName != "remark" &&
                                      nextElement.fieldName != "belongtoid" &&
                                      nextElement.fieldName != "expiredate" &&
                                      nextElement.fieldName != "status" &&
                                      nextElement.fieldName != "id" &&
                                      nextElement.fieldValue &&
                                      nextElement.fieldValue != "true" &&
                                      nextElement.fieldValue != "false" &&
                                      nextElement.fieldValue != "0" &&
                                      nextElement.fieldValue != "1"
                                    ) {
                                      fieldsList.push(nextElement);
                                    }
                                  } else if (self.objectApi == "Event") {
                                    if (
                                      fieldsList.length < 5 &&
                                      nextElement.fieldName != "subject" &&
                                      nextElement.fieldName != "remark" &&
                                      nextElement.fieldName != "belongtoid" &&
                                      nextElement.fieldName != "begintime" &&
                                      nextElement.fieldName != "endtime" &&
                                      nextElement.fieldName != "id" &&
                                      nextElement.fieldValue &&
                                      nextElement.fieldValue != "true" &&
                                      nextElement.fieldValue != "false" &&
                                      nextElement.fieldValue != "0" &&
                                      nextElement.fieldValue != "1"
                                    ) {
                                      fieldsList.push(nextElement);
                                    }
                                  }
                                }
                              );
                            }
                          } else {
                            res.data[0].cardList[i].fieldsList.forEach(
                              (item) => {
                                if (
                                  fieldsList.length < 5 &&
                                  item.fieldValue != null &&
                                  item.fieldValue != "true" &&
                                  item.fieldValue != "false" &&
                                  item.fieldValue != "0" &&
                                  item.fieldValue != "1" &&
                                  item.filedName != "id"
                                ) {
                                  fieldsList.push(item);
                                }
                              }
                            );
                          }
                          await detailSendCard(
                            res.data[0].cardList[i].groupId,
                            {
                              // 新建
                              title:
                                self.$i18n.t("label.ems.create") +
                                res.data[0].cardList[i].objName,
                              recordId: res.data[0].id,
                              type: "NORMAL_CARD",
                              body: fieldsList,
                              changer: localStorage.getItem("username"),
                            }
                          );
                        }, 500 * i);
                      })(i);
                    }
                  }
                  this.recordId = res.data[0].id;
                  if (this.recordId !== undefined && this.recordId !== "") {
                    // 保存从对象数据
                    if (this.subordinateFields.length > 0) {
                      this.saveRelatedData(flag);
                    } else {
                      this.cancel();
                      // 不是保存并提交时才给保存成功提示
                      if (flag !== "saveandapproval") {
                        this.$message.success(
                          this.$i18n.t("label.search.saveok")
                        );
                        this.$emit("update");
                      }
                      // if (this.$route.path.indexOf("detail") != "-1") {
                      this.$emit("save", this.recordId);
                      // }
                      // 保存并新建
                      if (flag === "saveAndRenew") {
                        this.add();
                      } else if (flag === "saveandapproval") {
                        this.saveProductInfo();
                        //获取创建的订单id
                        this.orderId = res.data[0].id;
                        //获取客户id
                        this.productInfo.map((item) => {
                          item.orderid = res.data[0].id;
                          item.shippingaccount = JSON.parse(
                            params.data
                          )[0].accountid;
                          item.name = res.data[0].id;
                        });
                        let obj = {
                          data: JSON.stringify(this.productInfo),
                          objectApi: "cloudccorderitem",
                          marketsea: "false",
                        };
                        CommonObjApi.save(obj).then(() => {});
                        // 提交待审批
                        submitApproval({ relatedId: this.recordId })
                          .then((res) => {
                            // 提交待审批成功
                            if (res.result) {
                              this.$message({
                                message: this.$i18n.t(
                                  "label.webtocloudcc.submit.success"
                                ),
                                type: "success",
                              });
                              this.$router.push({
                                path: `/commonObjects/detail/${this.recordId}/DETAIL`,
                                query: {
                                  objId: this.realObjId,
                                },
                              });
                            } else {
                              if (res.returnCode == "Manual") {
                                this.$router.push({
                                  path: `/commonObjects/detail/${this.recordId}/DETAIL`,
                                  query: {
                                    objId: this.realObjId,
                                    isManual: true,
                                    recordId: this.recordId,
                                  },
                                });
                                // TODO 弹窗填写审批信息
                                //  this.$refs.nextApprovalref.IsShowdialog(
                                //   true,
                                //   this.recordId
                                // );
                              } else if (res.returnCode !== "Manual") {
                                this.errInfo = res.returnInfo;
                                this.failedDialog = true;
                              }
                            }
                          })
                          .catch((err) => {
                            if (err.returnCode == "Manual") {
                              // TODO 弹窗填写审批信息
                              this.$refs.nextApprovalref.IsShowdialog(
                                true,
                                this.recordId
                              );
                            } else if (err.returnCode !== "Manual") {
                              this.errInfo = err.returnInfo;
                              this.failedDialog = true;
                            }
                            this.copyJumpDetail();
                          });
                      } else if (this.type !== "active") {
                        // 仅保存 type为active时,不跳转对应详情
                        this.saveProductInfo();
                        //获取创建的订单id
                        this.orderId = res.data[0].id;
                        //获取客户id
                        this.productInfo.map((item) => {
                          item.orderid = res.data[0].id;
                          item.shippingaccount = JSON.parse(
                            params.data
                          )[0].accountid;
                          item.name = res.data[0].id;
                        });
                        let obj = {
                          data: JSON.stringify(this.productInfo),
                          objectApi: "cloudccorderitem",
                          marketsea: "false",
                        };
                        CommonObjApi.save(obj).then(() => {
                          // this.orderId=res.data[0].id
                        });
                        if (flag === "pay") {
                          this.$router.push({
                            path: `orderPay/${this.totalPrice}/${this.orderId}`,
                          });
                        } else if (flag === "detail") {
                          this.$router.push({
                            path: `/commonObjects/detail/${this.recordId}/DETAIL`,
                            query: {
                              objId: this.realObjId,
                            },
                          });
                        }
                      }
                    }
                  } else {
                    // 字段重复值特殊处理
                    if (res.data[0].duplicatemap) {
                      this.duplicate_name =
                        res.data[0].duplicatemap.duplicate_name;
                      this.duplicate_labelname =
                        res.data[0].duplicatemap.duplicate_labelname;
                      this.duplicateHref = `#/commonObjects/detail/${res.data[0].duplicatemap.duplicate_id}/DETAIL`;
                      this.duplicateFieldDialogAttr.isShow = true;
                    } else {
                      this.$message.error(res.data[0].errormessage);
                    }
                  }
                } else {
                  // 查找字段保存时，存在筛选条件不符，页面定位指定字段，并给出提示
                  if(res?.data && res.data[0]?.errormessage.includes('illegal_field_apiname')){
                    // 值不存在或与筛选条件不匹配
                    this.$message.error(this.$i18n.t("c2420"));
                  }else{
                    this.$message.error(
                      res.data[0].errormessage || this.$i18n.t("chatter_save_f")
                    );
                  }
                }
              })
              .catch(() => {
                this.isSaveBtnLoadin = false;
              });
          }
          this.deletechooseproduct();
        } else {
          this.verifyJumpFn({}, true);
          // 存在验证错误，请检查填写的内容
          this.$message.warning(
            this.$i18n.t("vue_label_commonobjects_detail_check_the_content")
          );
        }
      });
    },
    //复制跳转详情页
    copyJumpDetail() {
      if (this.dialogAttr.type === "COPY") {
        this.$router.push({
          path: `/commonObjects/detail/${this.recordId}/DETAIL`,
          query: {
            objId: this.realObjId,
          },
        });
      }
    },
    cancelRecordTypeDialog() {
      // this.type = "";
      this.recordTypeDialogAttr.isShow = false;
    },
    // 弹窗关闭
    cancel() {
      this.contactes = "";
      this.dialogAttr.isShow = false;
      this.isSaveBtnLoadin = false;
      this.subordinateFields = [];
      this.alldataists = [];
      this.dataLists = [];
      this.row = "";
      this.$emit("reloadgatee");
    },
    // 详情
    detail(row) {
      // 查找字段跳到对应详情页
      if (row.field.schemefieldType === "Y") {
        CommonObjApi.getPermissionById({
          id: row.data[row.field.apiname],
        }).then((res) => {
          if (res.data.isQuery) {
            this.$router.push({
              path: `/commonObjects/detail/${
                row.data[row.field.apiname]
              }/DETAIL`,
            });
          } else {
            this.$message.warning(
              "对不起，您暂无权限查看这条数据的详细信息，如有疑问请联系系统管理员"
            );
          }
        });
      } else {
        this.$router.push({
          path: `/commonObjects/detail/${row.data.id}/DETAIL`,
          query: {
            viewId: this.viewId,
          },
        });
      }
    },
    //主从录入
    getZhuCong(layoutId) {
      this.relatedObjectApis = [];
      this.relatedApinames = [];
      this.relatedControlContainer = [];
      this.relatedLookupValues = [];
      let params = {
        objId: this.$route.params.objId || this.objId || this.realObjId,
        layoutId: layoutId,
      };
      if (this.dialogAttr.type === "EDIT") {
        params.recordId = this.id;
      }
      CommonObjApi.getMasterInfo(params)
        .then((res) => {
          this.subordinateFields = res.data;

          for (var i = 0; i < this.subordinateFields.length; i++) {
            // 业务机会阶段变化时可能性和预测类别字段跟随变化
            if (this.subordinateFields[i].objectApi === "Opportunity") {
              this.getKNXSelectValue();
            }
            this.relatedObjectApis.push(this.subordinateFields[i].objectApi);
            this.relatedApinames.push(this.subordinateFields[i].relatedApiname);
            if (this.dialogAttr.type === "EDIT") {
              let objectData = [];
              // 循环每一个相关列表
              if (
                this.subordinateFields[i].data &&
                this.subordinateFields[i].data.length > 0
              ) {
                // 循环每一行数据 每行数据data不一定有值
                this.subordinateFields[i].data.forEach((data) => {
                  let addData = {};
                  for (const key in data) {
                    this.$set(addData, key, { value: data[key] });
                    // 查找字段回显
                    if (data[`${key}Name`]) {
                      let option = [
                        {
                          label: data[`${key}Name`],
                          value: data[key],
                        },
                      ];
                      this.$set(addData, `${key}Options`, option);
                    }
                  }
                  objectData.push(addData);
                });
              }
              this.dataLists.push(objectData);
            } else {
              this.dataLists.push([{}, {}]);
            }
            for (
              var j = 0;
              j < this.subordinateFields[i].fieldList.length;
              j++
            ) {
              // 姓、名、自动编号字段不显示
              if (
                this.subordinateFields[i].fieldList[j].type === "V" ||
                this.subordinateFields[i].fieldList[j].label === "姓" ||
                this.subordinateFields[i].fieldList[j].label === "名"
              ) {
                this.subordinateFields[i].fieldList.splice(j, 1);
                j--;
              } else {
                // 重置列表数据
                for (let num = 0; num < this.dataLists[i].length; num++) {
                  if (this.dialogAttr.type !== "EDIT") {
                    this.$set(
                      this.dataLists[i][num],
                      this.subordinateFields[i].fieldList[j].name,
                      { value: "" }
                    );
                  }

                  // 选项列表、选项列表多选字段设置选项
                  if (
                    INPUTTYPE[this.subordinateFields[i].fieldList[j].type] ===
                      "select" ||
                    INPUTTYPE[this.subordinateFields[i].fieldList[j].type] ===
                      "multi-select"
                  ) {
                    this.$set(
                      this.dataLists[i][num],
                      `${this.subordinateFields[i].fieldList[j].name}Options`,
                      this.subordinateFields[i].fieldList[j].selectOptions
                    );
                  }

                  // 百分数和数字字段字数长度限制
                  if (
                    this.subordinateFields[i].fieldList[j].type === "P" ||
                    INPUTTYPE[this.subordinateFields[i].fieldList[j].type] ===
                      "number"
                  ) {
                    this.subordinateFields[i].fieldList[j].valueInterval = {
                      min:
                        0 -
                        Math.pow(
                          10,
                          this.subordinateFields[i].fieldList[j].length -
                            this.subordinateFields[i].fieldList[j]
                              .decimalPlaces -
                            1
                        ) +
                        1,
                      max:
                        Math.pow(
                          10,
                          this.subordinateFields[i].fieldList[j].length -
                            this.subordinateFields[i].fieldList[j]
                              .decimalPlaces -
                            1
                        ) - 1,
                    };
                  }
                }

                // 字段依赖事件
                if (this.subordinateFields[i].fieldList[j].isContrField) {
                  this.$set(
                    this.subordinateFields[i].fieldList[j],
                    "changeEvents",
                    "control"
                  );
                }
                this.$set(
                  this.subordinateFields[i].fieldList[j],
                  "fieldType",
                  INPUTTYPE[this.subordinateFields[i].fieldList[j].type]
                );
              }
              // // 字段依赖事件
              // if (this.subordinateFields[i].fieldList[j].isContrField) {
              //   this.$set(this.subordinateFields[i].fieldList[j], "changeEvents", "control")
              // }
              // this.$set(this.subordinateFields[i].fieldList[j], "fieldType", INPUTTYPE[this.subordinateFields[i].fieldList[j].type])
            }

            // 字段依赖性
            this.relatedControlContainer = this.relatedControlContainer.concat(
              JSON.parse(this.subordinateFields[i].controlContainer)
            );
            // 查找带值，绑定事件
            if (
              this.subordinateFields[i].lookupValueExpression !== undefined &&
              this.subordinateFields[i].lookupValueExpression !== "" &&
              this.subordinateFields[i].lookupValueExpression !== null
            ) {
              this.setRelatedLookupValue(
                JSON.parse(this.subordinateFields[i].lookupValueExpression)
              );
            }
          }
        })
        .catch(() => {});
    },
    // 获取 阶段-可能性 对应关系
    getKNXSelectValue() {
      CommonObjApi.getSelectValue({ fieldId: "ffe201100003943eFUc8" }).then(
        (res) => {
          if (res.result && res.returnCode === "1") {
            let options = [];
            res.data.forEach((item) => {
              options.push({
                val: item.codevalue,
                key: item.codekey,
                knx: Number(item.knx),
                forecasttype: item.forecasttype,
              });
            });
            this.possibilityList = options;
          } else {
            this.$message.error(res.returnInfo);
          }
        }
      );
    },
    // 主从录入查找带值
    setRelatedLookupValue(lookupValueExpression) {
      lookupValueExpression.forEach((item) => {
        this.subordinateFields.forEach((zhucong) => {
          zhucong.fieldList.forEach((attr) => {
            if (attr.name === item.expression.split(",")[0].toLowerCase()) {
              attr.changeEvents =
                attr.changeEvent === undefined
                  ? "lookupValue"
                  : `${attr.changeEvent},lookupValue`;
            }
          });
        });
        this.relatedLookupValues.push(item);
      });
    },
    // 主从录入查找带值
    relatedLookupValue(selectVal, fieldName) {
      this.relatedLookupValues.forEach((lookupValue) => {
        if (lookupValue.expression.split(",")[0].toLowerCase() === fieldName) {
          this.getLookupValue(lookupValue, selectVal);
        }
      });
    },
    // 主从录入查找带值
    getLookupValue(lookupRule, id, formData) {
      // id: 查找记录的id
      let fieldJson = [];
      let carryValue = "";

      // 查找字段值为空时不走带值规则
      if (id.toString() !== "") {
        fieldJson.push({
          fieldrelateid: lookupRule.id,
          id: id,
          expression: lookupRule.expression,
          iscover: lookupRule.iscover,
        });
        let param = {
          objId: this.prefix,
          fieldJson: JSON.stringify(fieldJson),
        };
        if (this.isZhucongfield) {
          // 从对象前缀
          param.objId = this.relatedObjPrefix;
        }
        CommonObjApi.getLookupRelatedFieldValue(param).then((res) => {
          carryValue =
            res.data && res.data.length > 0 ? res.data[0].value : null;
          // 区分主从录入查找带值
          if (this.isZhucongfield) {
            this.$refs.EditableCell.forEach((editableCell) => {
              if (
                editableCell.lineIndex === this.rowIndex - 1 &&
                editableCell.fieldId === lookupRule.id
              ) {
                editableCell.editValue = carryValue;
              }
            });
          } else {
            // 主对象查找带值
            // 编辑时，如果要带值的字段值为空，带的值必须带过去，如果值不为空，iscover为‘true’时才带值，要带值的字段有可能是查找字段，需要处理
            this.formAttr.forEach((attr) => {
              if (attr.children) {
                attr.children.forEach((attr) => {
                  this.queryGeneraValue(
                    attr,
                    res,
                    formData,
                    lookupRule,
                    carryValue
                  );
                });
              }
              this.queryGeneraValue(
                attr,
                res,
                formData,
                lookupRule,
                carryValue
              );
            });
          }
        });
      }
    },
    //查找代值方法封装
    queryGeneraValue(attr, res, formData, lookupRule, carryValue) {
      if (attr.fieldId === lookupRule.id) {
        // 新建时必须修改
        if (this.dialogAttr.type === "NEW" || this.dialogAttr.type === "COPY") {
          // 查找、查找多选、主详字段特殊处理
          if (
            (attr.fieldType === "Y" ||
              attr.fieldType === "MR" ||
              attr.fieldType === "M") &&
            res.data[0].value &&
            res.data[0].lkid &&
            res.data[0].value !== "" &&
            res.data[0].lkid !== ""
          ) {
            this.optionList[attr.prop] = [
              {
                value: res.data[0].lkid,
                label: res.data[0].value,
              },
            ];
          }
          // formData !== undefined表示表单数据变化触发查找带值
          if (formData !== undefined) {
            // 查找、查找多选字段特殊处理
            if (
              (attr.fieldType === "Y" || attr.fieldType === "M") &&
              res.data[0].lkid &&
              res.data[0].lkid !== ""
            ) {
              formData[attr.prop] = res.data[0].lkid;
            } else if (
              attr.fieldType === "MR" &&
              res.data[0].lkid &&
              res.data[0].lkid !== ""
            ) {
              formData[attr.prop] = [res.data[0].lkid];
            } else {
              if (
                (attr.fieldType === "P" ||
                  attr.fieldType === "N" ||
                  attr.fieldType === "c") &&
                (carryValue === "" || carryValue === null)
              ) {
                formData[attr.prop] = undefined;
              } else {
                formData[attr.prop] = carryValue;
              }
            }
          } else {
            if (
              (attr.fieldType === "Y" || attr.fieldType === "M") &&
              res.data[0].lkid &&
              res.data[0].lkid !== ""
            ) {
              attr.value = res.data[0].lkid;
              this.$refs.baseForm &&
                this.$set(
                  this.$refs.baseForm.formData,
                  attr.prop,
                  res.data[0].lkid
                );
            } else if (
              attr.fieldType === "MR" &&
              res.data[0].lkid &&
              res.data[0].lkid !== ""
            ) {
              attr.value = [res.data[0].lkid];
              this.$refs.baseForm &&
                this.$set(this.$refs.baseForm.formData, attr.prop, [
                  res.data[0].lkid,
                ]);
            } else {
              attr.value = carryValue;
              this.$refs.baseForm &&
                this.$set(this.$refs.baseForm.formData, attr.prop, carryValue);
            }
          }
        } else if (this.dialogAttr.type === "EDIT") {
          // 编辑时
          // formData !== undefined表示表单数据变化触发查找带值
          if (formData !== undefined) {
            // 如果要带值的字段值为空，带的值必须带过去   如果值不为空，iscover为‘true’时才带值
            if (
              formData[attr.prop] === "" ||
              formData[attr.prop] === null ||
              (formData[attr.prop] instanceof Array &&
                formData[attr.prop].length === 0) ||
              lookupRule.iscover === "true"
            ) {
              // 查找、查找多选字段特殊处理
              if (
                (attr.fieldType === "Y" ||
                  attr.fieldType === "MR" ||
                  attr.fieldType === "M") &&
                res.data[0].value &&
                res.data[0].lkid &&
                res.data[0].value !== "" &&
                res.data[0].lkid !== ""
              ) {
                this.optionList[attr.prop] = [
                  {
                    value: res.data[0].lkid,
                    label: res.data[0].value,
                  },
                ];
                if (res.data[0].lkid !== "") {
                  formData[attr.prop] =
                    attr.fieldType === "Y" || attr.fieldType === "M"
                      ? res.data[0].lkid
                      : [res.data[0].lkid];
                }
              } else {
                if (
                  (attr.fieldType === "P" ||
                    attr.fieldType === "N" ||
                    attr.fieldType === "c") &&
                  (carryValue === "" || carryValue === null)
                ) {
                  formData[attr.prop] = undefined;
                } else {
                  formData[attr.prop] = carryValue;
                }
              }
            }
          } else {
            // 如果要带值的字段值为空，带的值必须带过去 如果值不为空，iscover为‘true’时才带值
            if (
              attr.value === undefined ||
              attr.value === "" ||
              attr.value === null ||
              lookupRule.iscover === "true"
            ) {
              if (
                (attr.fieldType === "Y" ||
                  attr.fieldType === "MR" ||
                  attr.fieldType === "M") &&
                res.data[0].value &&
                res.data[0].lkid &&
                res.data[0].value !== "" &&
                res.data[0].lkid !== ""
              ) {
                this.optionList[attr.prop] = [
                  {
                    value: res.data[0].lkid,
                    label: res.data[0].value,
                  },
                ];
                if (res.data[0].lkid !== "") {
                  attr.value =
                    attr.fieldType === "Y" || attr.fieldType === "M"
                      ? res.data[0].lkid
                      : [res.data[0].lkid];
                  this.$refs.baseForm &&
                    this.$set(
                      this.$refs.baseForm.formData,
                      attr.apiname,
                      attr.fieldType === "Y" || attr.fieldType === "M"
                        ? res.data[0].lkid
                        : [res.data[0].lkid]
                    );
                }
              } else {
                attr.value = carryValue;
                this.$refs.baseForm &&
                  this.$set(
                    this.$refs.baseForm.formData,
                    attr.apiname,
                    carryValue
                  );
              }
            }
          }
        }

        // 带值字段如果有字段依赖、查找、动态布局规则，执行
        let changeEvent =
          attr.changeEvent !== undefined ? attr.changeEvent.split(",") : [];
        changeEvent.forEach((eventItem) => {
          if (eventItem === "control") {
            this.control(
              attr.value || this.$refs.baseForm.formData[attr.prop],
              attr.fieldId,
              this.$refs.baseForm.formData
            );
          } else if (eventItem === "lookupValue") {
            this.lookupValue(
              attr.value || this.$refs.baseForm.formData[attr.prop],
              attr,
              this.$refs.baseForm.formData
            );
          } else if (eventItem === "handleAction") {
            this.handleAction();
          }
        });
      }
    },
    // 主从录入依赖字段
    relatedControl(selectVal, lineIndex, fieldId, subordinateIndex) {
      // 查找对应规则
      let control = this.relatedControlContainer.find((item) => {
        return item.controlfield === fieldId;
      });

      if (control !== undefined) {
        // 先将被控字段选项清空,循环后有对应选项再进行赋值
        control.controlVals.forEach((vals) => {
          vals.dependFields.forEach((dependField) => {
            this.$refs.EditableCell.forEach((editableCell) => {
              if (
                editableCell.lineIndex === lineIndex &&
                editableCell.fieldId === dependField.dependfieldid
              ) {
                this.$set(
                  this.dataLists[subordinateIndex][lineIndex],
                  [`${editableCell.fieldName}Options`],
                  []
                );
              }
            });
          });
        });

        // 查找当前值是否有控制规则
        let controlCondition = control.controlVals.find((item) => {
          return item.val === selectVal;
        });
        if (controlCondition === undefined) {
          control.controlVals.forEach((vals) => {
            // 控制值
            vals.dependFields.forEach((dependField) => {
              this.$refs.EditableCell.forEach((editableCell) => {
                if (
                  editableCell.lineIndex === lineIndex &&
                  editableCell.fieldId === dependField.dependfieldid
                ) {
                  editableCell.editValue = "";
                  this.relatedControl(
                    "",
                    lineIndex,
                    dependField.dependfieldid,
                    subordinateIndex
                  );
                }
              });
            });
          });
        }

        // 控制条件
        control.controlVals.forEach((vals) => {
          if (vals.val === selectVal) {
            // 控制值
            vals.dependFields.forEach((dependField) => {
              this.$refs.EditableCell.forEach((editableCell) => {
                if (
                  editableCell.lineIndex === lineIndex &&
                  editableCell.fieldId === dependField.dependfieldid
                ) {
                  let options = [];
                  dependField.vals.forEach((element) => {
                    options.push({
                      label: element.deflangval,
                      value: element.val,
                    });
                  });
                  this.$set(
                    this.dataLists[subordinateIndex][lineIndex],
                    [`${editableCell.fieldName}Options`],
                    options
                  );
                  // 若当前选中值不在选项列表中，则清空
                  let option = options.find((item) => {
                    return item.value === editableCell.editValue;
                  });
                  if (option === undefined) {
                    // 清空被控字段值并递归
                    editableCell.editValue = "";
                    this.relatedControl(
                      "",
                      lineIndex,
                      dependField.dependfieldid,
                      subordinateIndex
                    );
                  }
                }
              });
            });
          }
        });
      }
    },
    //主从录入添加
    addRelatedData(ind) {
      this.dataLists[ind].push({});
      if (this.subordinateFields[ind] != "") {
        for (var i = 0; i < this.subordinateFields[ind].fieldList.length; i++) {
          // 重置新增加行的列表数据
          this.$set(
            this.dataLists[ind][this.dataLists[ind].length - 1],
            this.subordinateFields[ind].fieldList[i].name,
            { value: "" }
          );
          // 设置选项列表值
          if (
            INPUTTYPE[this.subordinateFields[ind].fieldList[i].type] ===
              "select" ||
            INPUTTYPE[this.subordinateFields[ind].fieldList[i].type] ===
              "multi-select"
          ) {
            this.$set(
              this.dataLists[ind][this.dataLists[ind].length - 1],
              `${this.subordinateFields[ind].fieldList[i].name}Options`,
              this.subordinateFields[ind].fieldList[i].selectOptions
            );
          }
        }
      }
      if (this.dataLists[ind].length >= 30) {
        this.isShowThree = false;
      }
    },
    //主从录入删除
    deleteUl(num, ind) {
      // 编辑时删除从对象记录走权限，调用删除数据接口  id不为空时表明不是新增数据，走删除接口
      if (this.dialogAttr.type === "EDIT" && this.dataLists[ind][num].id) {
        CommonObjApi.getPermissionById({
          id: this.dataLists[ind][num].id.value,
        }).then((res) => {
          if (res.returnCode === "1" && res.result) {
            if (res.data.isDelete) {
              // 删除数据
              CommonObjApi.del({
                id: this.dataLists[ind][num].id.value,
                objectApi: this.relatedObjectApis[ind],
              }).then(() => {
                // 主从录入可将数据全部删除
                if (
                  this.subordinateFields[ind] !== "" &&
                  this.dataLists[ind].length > 0
                ) {
                  this.dataLists[ind].splice(num, 1);
                }
                if (this.dataLists[ind].length < 30) {
                  this.isShowThree = true;
                }
              });
            }
          } else {
            this.$message.error(res.returnInfo);
          }
        });
      } else {
        // 主从录入可将数据全部删除
        if (
          this.subordinateFields[ind] !== "" &&
          this.dataLists[ind].length > 0
        ) {
          this.dataLists[ind].splice(num, 1);
        }
        if (this.dataLists[ind].length < 30) {
          this.isShowThree = true;
        }
      }
    },
    // 编辑值改变
    editValueChange(val, lineIndex, field, ind) {
      this.dataLists[ind][lineIndex][field] = { value: val };

      // 业务机会对象可能性随阶段变化显示对应的值
      if (
        this.relatedObjectApis[ind] === "Opportunity" &&
        field === "jieduan"
      ) {
        let knx = this.possibilityList.find((item) => {
          return item.val === val;
        });
        // 找是否存在可能性和预测类别字段
        let knxField = this.subordinateFields[ind].fieldList.find((item) => {
          return item.name === "knx";
        });
        let forecasttypeField = this.subordinateFields[ind].fieldList.find(
          (item) => {
            return item.name === "forecasttype";
          }
        );
        if (knx && knxField) {
          this.$set(this.dataLists[ind][lineIndex], "knx", {
            value: knx.knx.toString(),
          });
        }
        if (knx && forecasttypeField) {
          this.$set(this.dataLists[ind][lineIndex], "forecasttype", {
            value: knx.forecasttype,
          });
        }
      }
    },
    //获取保存信息
    savemessages() {
      this.alldataists = [];
      this.validRelatedObj = true;
      this.isRepeatTime = false; //赋初值

      for (let i = 0; i < this.dataLists.length; i++) {
        let dataArr = [];
        let drr = {};
        for (let line = 0; line < this.dataLists[i].length; line++) {
          let obj = {};
          // 编辑时保存数据id
          if (this.dataLists[i][line].id && this.dataLists[i][line].id.value) {
            obj.id = this.dataLists[i][line].id.value;
          }
          this.$refs.EditableCell &&
            this.$refs.EditableCell.forEach((editableCell) => {
              if (
                editableCell.lineIndex === line &&
                editableCell.objectApi === this.relatedObjectApis[i] &&
                editableCell.editValue !== null
              ) {
                obj[editableCell.field] = editableCell.editValue.toString();
                if (
                  editableCell.required &&
                  (editableCell.editValue === "" ||
                    editableCell.editValue === undefined ||
                    editableCell.editValue === null)
                ) {
                  this.validRelatedObj = false;
                  this.nullElement = editableCell;
                }
              }
            });
          // 业务机会若存在可能性字段 --- 接口处理
          // if (this.relatedObjectApis[i] === 'Opportunity' && 'jieduan' in obj) {
          //   let knx = this.possibilityList.find((item) => {
          //     return item.val === obj['jieduan']
          //   })
          //   // 不存在可能性和预测类别字段时才强制赋值保存
          //   if (!('knx' in obj) && knx) {
          //     obj['knx'] = knx.knx.toString()
          //   }
          //   if (!('forecasttype' in obj) && knx) {
          //     obj['forecasttype'] = knx.forecasttype
          //   }
          // }
          dataArr.push(obj);
        }
        // 判断是否有重复时间段
        for (let i = 0; i < dataArr.length; i++) {
          for (let j = i + 1; j < dataArr.length; j++) {
            // 先判断是否有重复的日期
            if (dataArr[i].dayofweek == dataArr[j].dayofweek) {
              // 有，则判断时间段
              let repeatDataArr = [dataArr[i], dataArr[j]];
              let timeArr = [];
              for (let n in repeatDataArr) {
                let obj = {};
                obj.startPeriod = repeatDataArr[n].starttime;
                obj.endPeriod = repeatDataArr[n].endtime;
                timeArr.push(obj);
              }
              this.isRepeatTime = this.isHasRepeatTime(timeArr); //结果赋值给判断变量
            }
          }
        }
        //
        this.row += dataArr.length;
        drr.datalist = JSON.parse(JSON.stringify(dataArr));
        drr.objectApi = this.relatedObjectApis[i];
        drr.relatedApiname = this.relatedApinames[i];
        this.alldataists.push(drr);
      }
    },
    // 判断重复时间段
    isHasRepeatTime(data) {
      const startTimeArr = [];
      const endTimeArr = [];
      (data || []).map(function (item) {
        startTimeArr.push(item.startPeriod);
        endTimeArr.push(item.endPeriod);
      });
      const allStartTime = startTimeArr.sort();
      const allEndTime = endTimeArr.sort();
      let result = 0;
      for (let k = 1; k < allStartTime.length; k++) {
        if (allStartTime[k] < allEndTime[k - 1]) {
          result += 1;
        }
      }
      return result > 0;
    },
    //主从录入保存
    saveRelatedData(flag) {
      let param = {
        recordId: this.recordId,
        data: JSON.stringify(this.alldataists),
      };
      CommonObjApi.saveMaster(param)
        .then((res) => {
          let errorMsg = "";
          res.data.forEach((item) => {
            if (item.isSaveSuccess !== "true") {
              errorMsg += this.$i18n.t(
                "vue_label_commonobjects_view_notice_deletefailreason",
                [item.id, item.errormessage]
              );
            }
          });
          if (errorMsg === "") {
            this.cancel();
            // 不是保存并提交时才给保存成功提示
            if (flag !== "saveandapproval") {
              this.$message.success(this.$i18n.t("label.search.saveok"));
            }
            this.$emit("save", this.recordId);
            // 保存并新建
            if (flag === "saveAndRenew") {
              this.add();
            } else if (flag === "saveandapproval") {
              // 提交待审批
              submitApproval({ relatedId: this.recordId })
                .then((res) => {
                  // 提交待审批成功
                  if (res.result) {
                    this.$message({
                      message: this.$i18n.t(
                        "label.webtocloudcc.submit.success"
                      ),
                      type: "success",
                    });
                    this.$router.push({
                      path: `/commonObjects/detail/${this.recordId}/DETAIL`,
                      query: {
                        objId: this.realObjId,
                      },
                    });
                  }
                })
                .catch((err) => {
                  if (err.returnCode == "Manual") {
                    // TODO 弹窗填写审批信息
                    this.$refs.nextApprovalref.IsShowdialog(
                      true,
                      this.recordId
                    );
                  } else if (err.returnCode !== "Manual") {
                    this.errInfo = err.returnInfo;
                    this.failedDialog = true;
                  }
                });
            } else if (this.type !== "active") {
              // 仅保存 type为active,不跳转对应详情

              this.$router.push({
                path: `/commonObjects/detail/${this.recordId}/DETAIL`,
                query: {
                  objId: this.realObjId,
                },
              });
            }
          } else {
            this.$message.error(errorMsg);
          }
        })
        .catch(() => {});
    },
    async xiaoshou() {
      let params = {
        obj: this.getViewListDataParams.obj,
        viewId: this.$route.params.viewId,
      };
      let res = await getViewListRecently(params);
      if (res.result) {
        window.location.href = res.data.toolsBtn[0].url;
      }
    },
    fwbContent(val) {
      this.fwb = val;
    },
  },
  watch: {
    recordType(nval) {
      if (nval == "none") {
        // eslint-disable-next-line vue/no-mutating-props
        this.recordType = "";
        return;
      }
      this.next();
    },
  },
  beforeDestroy() {
    Bus.$off("windowResize", this.ebFn);
    localStorage.setItem("relevantObjectLevel", 0);
  },
};
</script>

<style lang="scss" scoped>
.setColumnsIcon {
  width: 16px;
  height: 28px;
  margin-left: 8px;
}

.autoColumnsIcon {
  width: 16px;
  margin-left: 7px;
}

.groupTitle {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  padding-left: 20px;
  margin-bottom: 10px;
  background: #f0f0f0;
}

.selectnum {
  height: 12px;
  font-size: 12px;
  margin-top: -20px;
  font-weight: 400;
  color: #666666;
  padding-bottom: 22px;
}
.checkboxs {
  margin-left: 6px;
  font-size: 12px;
  line-height: 28px;
}

.relatedListName {
  background: #f0f0f0;
  color: #606266;
  line-height: 40px;
  padding-left: 20px;
  margin-bottom: 20px;
}

.spancolor {
  color: #fa6400;
}
.spans {
  height: 12px;
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  line-height: 18px;
  padding-top: 12px;
}
.mtransferli {
  list-style: inside;
  height: 34px;
  font-size: 12px;

  font-weight: 400;
  color: #0c0b0b;
  line-height: 18px;
}

.editableCell {
  height: 40px;
}

::v-deep .changeColumnBtnGroup {
  margin-left: 10px;
  height: 30px;
  line-height: 30px;
  float: left;
  border: 1px solid #dedcda;
  border-radius: 3px;

  li {
    float: left;
    width: 30px;
    height: 30px;
    padding-top: 1px;

    img {
      margin-top: -2px;
    }
  }
}

/*批量新增css*/
.tank {
  height: 480px;
  background: rgba(255, 255, 255, 1);
  z-index: 6;
  margin: 100px auto 0;
}
.title {
  height: 53px;
  line-height: 53px;
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  border-bottom: 1px solid rgba(240, 240, 240, 1);
  color: rgba(109, 114, 120, 1);
  font-size: 18px;
}
.btnp {
  font-weight: bold;
  font-size: 17px;
}
.btnx {
  font-size: 16px;
}

.listulFir {
  height: 40px;
  min-width: 100%;
  color: #2a2a2a;
  border: 1px solid #d8d8d8;
  border-right: none;
  border-left: none;
  display: inline-flex;
  background: #fafaf9;
  position: relative;
}
.listulSec:nth-of-type(2n) {
  height: 40px;
  display: inline-flex;
  min-width: 100%;
  border-bottom: 1px solid #d8d8d8;
}
.listulSec:nth-of-type(2n + 1) {
  height: 40px;
  display: inline-flex;
  background: white;
  min-width: 100%;
  border-bottom: 1px solid #d8d8d8;
}
.firstLi {
  color: #006dcc;
  border-right: 1px solid#D8D8D8;
  min-width: 80px;
  text-align: center;
}
.secondLi {
  border-right: 1px solid #d8d8d8;
  text-align: center;
  width: 180px;
  line-height: 40px;
}
::v-deep .el-input {
  height: 30px;
  width: 100%;
  text-align: center;
}
::v-deep .el-input__inner {
  height: 30px;
  width: 100%;
}
::v-deep .el-button--mini {
  padding: 7px 15px;
}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: rgba(51, 51, 51, 1);
}
::v-deep .el-input__icon {
  height: auto;
}
.al {
  width: auto;
  overflow: auto;
  max-height: 250px;
  border: 1px solid #d8d8d8;
  border-top: none;
  border-bottom: none;
}
::v-deep .el-input__icon {
  margin-top: -4px;
}
.tianjia {
  margin-top: 20px;
  cursor: pointer;
  width: 32px;
  margin-bottom: 10px;
  margin-left: 16px;
}
ul {
  margin-bottom: 0;
}

.requiredIdentification {
  color: red;
  position: absolute;
  left: -1px;
  top: -14px;
}
.product-list {
  margin-top: 10px;
  overflow: auto;
  .product-body {
    width: 100%;
    .product {
      height: 40px;
      border: 1px solid #dedcda;
      border-radius: 2px;
      overflow: hidden;
      line-height: 26px;
      .item {
        font-size: 14px;
        float: left;
        width: calc(100% / 9 - 6px);
        height: 40px;
        border-right: 1px solid #dedcda;
        padding: 0 10px;
      }
      .title {
        font-weight: 600;
        line-height: 40px;
      }
      input {
        outline: none;
        height: 30px;
        width: 40px;
        border-radius: 4px;
        border: 1px solid #dcdcdc;
        padding-left: 5px;
        box-sizing: border-box;
      }
      .count {
        cursor: pointer;
        img {
          margin-left: 5px;
        }
      }
      .discount img {
        width: 30px;
        cursor: pointer;
      }
      .picture img {
        width: 60px;
      }
      .item:last-child {
        border: none;
      }
    }
    .list {
      height: 40px;
      border: none;
      border-bottom: 1px solid #dcdfe6;
      line-height: 40px;
      .item {
        border: none;
      }
    }
  }
}
.footer {
  position: absolute;
  bottom: 2px;
  height: 60px;
  width: calc(100% - 31px);
  background: #ffffff;
  border-top: 1px solid #dcdfe6;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: end;
  .priceTotal {
    height: 60px;
    margin: 15px 0px 0px 0px;
    div {
      span {
        margin: 0px 16px;
        p {
          font-weight: bold;
          display: inline-block;
        }
      }
    }
    .money {
      color: #f11b21;
      font-weight: bold;
      font-size: 20px;
    }
  }
  .span {
    font-size: 14px;
    float: right;
    color: #f11b21;
    margin-right: 34px;
  }
}
.create-edit-obj {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}
.detailHeight {
  height: calc(100% - 60px) !important;
  padding-bottom: 40px;
  overflow-y: auto;
  ::v-deep.el-form-item {
    margin-bottom: -5px;
    display: flex;
    flex-direction: column;
  }
  ::v-deep.el-form-item__label {
    width: 100% !important;
    padding-left: 20px;
  }
  ::v-deep.el-form-item__content {
    padding-left: 12px !important;
    margin-left: 0 !important;
    width: 90%;
  }
  ::v-deep.el-form-item__error {
    top: 30%;
    left: 2%;
  }
  ::v-deep.groupTitle {
    margin: 16px 0px 8px 0px;
    height: 36px;
    line-height: 36px;
    font-size: 14px;
    padding-left: 12px;
    margin-bottom: 10px;
    background: #f3f7ff;
    color: #080707;
    font-weight: bold;
    border-radius: 4px;
  }
}
::v-deep .el-input {
  .el-input__inner {
    height: 30px !important;
    overflow-x: auto;
    font-size: 14px !important;
  }
}
</style>
