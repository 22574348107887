<template>
  <div class="orderPayBody">
    <div class="titleS">
      <div class="name">
        <svg class="icon" aria-hidden="true"><use href="#icon-pay"></use></svg>
        <div class="descInfo">
          <div>
            <!-- 订单信息确认 -->
            <span class="nameSpan">{{
              $t("label.partnerCloud.orderingPlatform.orderInformation")
            }}</span>
            <!-- 币种： -->
            <span style="margin-left: 10px"
              >{{ $t("label.partnerCloud.orderingPlatform.currency")
              }}{{ currencyCode }}
            </span>
          </div>
          <div style="margin-top: 10px" v-if="recordOptions.length > 1">
            <span style="margin-right: 15px; font-size: 14px; color: #333333">{{
              $t("label.appupload.component.type.recordtype")
            }}</span>
            <!-- 请选择 -->
            <el-select
              v-model="recordType"
              filterable
              :placeholder="$t('label_tabpage_pleaseSelectz')"
            >
              <el-option
                v-for="item in recordOptions"
                :key="item.val"
                :label="item.key"
                :value="item.val"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <!-- 调整订单产品 -->
      <div class="button" @click="goShoppingCar">
        {{ $t("label.partnerCloud.orderingPlatform.adjust") }}
      </div>
    </div>
    <div class="orderBody">
      <!-- 新建编辑弹窗展示 -->
      <create-edit-obj
        ref="createEditObj"
        :prefix="prefix"
        :objectApi="objectApi"
        :recordType="recordType"
        @save="save"
        :listDate="listDate"
        :totalPrice="totalPrice"
        :priceBookName="priceBookName"
      >
      </create-edit-obj>
    </div>
  </div>
</template>

<script>
import createEditObj from "./create-edit-obj-cpoy.vue";
import * as CommonObjApi from "./api";

export default {
  components: {
    createEditObj,
  },
  data() {
    return {
      currencyCode: this.$store.state.currencyCode,
      prefix: "032",
      objectApi: "cloudccorder",
      listDate: [],
      //产品表头数据           //产品代码 产品名称 图片 单价 操作  购买数量  优惠  总价
      titleDate: [
        "",
        this.$i18n.t("label.product.code"),
        this.$i18n.t("label.product.name"),
        this.$i18n.t("label.file.img"),
        this.$i18n.t("label.partnerCloud.orderingPlatform.unitPrice"),
        this.$i18n.t("label.import.index.operation"),
        this.$i18n.t("label.partnerCloud.orderingPlatform.quantity"),
        this.$i18n.t("label.partnerCloud.orderingPlatform.preferential"),
        this.$i18n.t("lable.product.zongjia"),
      ],
      totalPrice: this.$route.query.totalPrice,
      priceBookName: this.$route.query.priceBookName,
      recordOptions: [],
      recordType: "", //记录类型
    };
  },
  created() {},
  mounted() {
    document.title = this.$setTitle(this.$i18n.t("label.ordering.platform"));
    this.getRecordType();
  },
  methods: {
    // 获取记录类型
    getRecordType() {
      this.recordOptions = [];
      CommonObjApi.getRecordType({ prefix: "032" }).then((res) => {
        if (
          res.data.recordTypeList !== null &&
          res.data.recordTypeList !== undefined
        ) {
          res.data.recordTypeList.forEach((record) => {
            // 设置默认记录类型
            if (record.isdefault === "true") {
              this.recordType = record.id;
            }

            return this.recordOptions.push({
              val: record.id,
              key: record.name,
            });
          });
          // 若没有默认记录类型，设置第一个为默认
          if (this.recordType === "") {
            this.recordType =
              this.recordOptions.length > 0 ? this.recordOptions[0].val : "";
          }
        } else {
          this.recordType = "none";
        }
      });
    },
    goShoppingCar() {
      this.$router.push({
        path: "/orderingShoppingCar",
        query: {
          priceBookName: this.priceBookName,
        },
      });
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.listDate = JSON.parse(to.query.listDate);
    });
  },
  watch: {
    recordType(nval) {
      if (nval !== "" && nval !== "null") {
        this.$refs.createEditObj.add();
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.orderPayBody {
  padding: 10px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
  height: 100%;
  // overflow: hidden;
}
.titleS {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 11px;
  border: 1px solid #dddbda;
  width: 100%;
  height: 72px;
}
.name {
  display: flex;
  align-items: center;
}
.name > .icon {
  width: 28px;
  height: 28px;
}
.nameSpan {
  font-size: 16px;
  font-weight: 600;
}
.button {
  padding: 5px 10px;
  border: 1px solid #dcdfe6;
  border-radius: 3px;
  cursor: pointer;
  background: white;
  color: #1890ff;
  display: none;
}
.orderBody {
  margin-top: 20px;
  background: #ffffff;
  border-radius: 3px;
  border: 1px solid #dddbda;
  height: calc(100% - 100px);
}
::v-deep .detailContentBox {
  padding: 0px !important;
}
::v-deep .detailContentBox {
  height: auto !important;
}
.descInfo {
  margin-left: 10px;
}
::v-deep .descInfo .el-input__inner {
  height: 22px;
  line-height: 22px;
}
::v-deep .descInfo .el-input__icon {
  line-height: 22px;
}
</style>